import ListGrandpasApp from "../../components/lists/ListGrandpasApp";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorScheme, User, UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import ViewContainer from "../../components/container/ViewContainer";
import ProfileDesktop from "../../components/profileDesktop/ProfileDesktop";
import styled from "styled-components";


const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflowx: auto;
	overridey: auto;
`;

const TableContainer = styled.div<{ $grandpa: UserScheme | null }>`
	margin: 0px 24px;

	@media (min-width: 768px) {
		margin: ${({ $grandpa }) =>
			$grandpa ? "0px 16px 0px 80px" : "0px 80px"};
	}
`;

const ProfileContainer = styled.div`
	margin: 0px 80px 0px 0px;
`;


function GrandpaView() {
	const navigate = useNavigate();
	const currentUser = useSelector((state: State) => state.currentUser);
	const [loading, setLoading] = useState(false);
	const [grandpas, setGrandpas] = useState<Array<UserScheme>>([]);
	const [grandpa, setGrandpa] = useState<UserScheme | null>(null);
	const [isDesktop, setIsDesktop] = useState(false);

	const getGrandpaList = useCallback(async () => {
		setLoading(true);
		try {
			const result = await User.get({
				type: "grandpa",
				residenceId: currentUser?.Residence?.objectId,
				state: ["registered", "waiting", "match"],
				limit: 500,
			});
			setGrandpas(result.data);
		} catch (e) {
			console.error((e as ErrorScheme).message);
		} finally {
			setLoading(false);
		}
	}, [currentUser]);
	

	const fetchData = useCallback(async () => {
		if (currentUser) {
			await getGrandpaList();
		}
	}, [currentUser, getGrandpaList]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	const handleSelectGrandpa = (grandpaInfo: UserScheme) => {
		if (isDesktop) {
			setGrandpa(grandpaInfo);
		} else {
			navigate("/abuelos/" + grandpaInfo.objectId, {
				state: { grandpaInfo },
			});
		}
	};

	const handleCloseProfile = () => {
		setGrandpa(null);
	};

	return (
		<ViewContainer $showMenu={true}>
			<ContentContainer>
				<>
					<TableContainer $grandpa={grandpa}>
						<ListGrandpasApp
							loading={loading}
							grandpas={grandpas}
							onSelectGrandpa={handleSelectGrandpa}
						/>
					</TableContainer>
					{isDesktop && grandpa ? (
						<ProfileContainer>
							<ProfileDesktop
								grandpa={grandpa}
								onCloseProfile={handleCloseProfile}
							/>
						</ProfileContainer>
					) : null}
				</>
			</ContentContainer>
		</ViewContainer>
	);
}

export default GrandpaView;
