import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Modal, Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { Auth, ErrorScheme } from "client-v2";
import { useEffect, useState } from "react";
import { X } from "lucide-react";

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
	justify-content: flex-start;
	background: ${ColorV2.surface.background};
	overflow: hidden;
`;

const Header = styled.header`
	display: flex;
	padding: 16px 0px;
	justify-content: flex-end;
	background: ${ColorV2.surface.background};
`;

const MobileButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
	justify-content: flex-start;
`;

const ClickableText = styled.div<{ $isActive: boolean }>`
	cursor: pointer;
	margin: 0px;
	background-color: ${({ $isActive }) =>
		$isActive ? ColorV2.surface.invert : ColorV2.transparent};
	border-radius: var(--radius-L, 12px);
`;

const StyledHr = styled.hr`
	border: none;
	border-top: 1px solid ${ColorV2.text.neutralSoft};
	margin: 16px 0;
`;

function Homepage(props : Props) {
	const navigate = useNavigate();
	const location = useLocation();

	const [selectedTab, setSelectedTab] = useState<"Mayores" | "Cartas">(
		"Mayores"
	);

	useEffect(() => {
		if (location.pathname === "/abuelos") {
			setSelectedTab("Mayores");
		} else if (location.pathname === "/cartas") {
			setSelectedTab("Cartas");
		}
	}, [location.pathname]);

	const handleTabClick = (tab: "Mayores" | "Cartas") => {
		if (tab === "Mayores" && location.pathname !== "/abuelos") {
			navigate("/abuelos");
		} else if (tab === "Cartas" && location.pathname !== "/cartas") {
			navigate("/cartas");
		}
	};

	const handleLogout = () => {
		Auth.logout().catch((error: ErrorScheme) => {
			console.error(error.message);
		});
		navigate("/login");
	};

	return (
		<Modal
			type="lateral"
			hideHeader={true}
			isVisible={props.isModalOpen}
			shouldCloseOnOverlayClick={true}
			style={{ backgroundColor: ColorV2.surface.background }}
			onClose={props.closeModal}
		>
			<MainContainer>
				<Header>
					<X
						height={24}
						width={24}
						style={{ padding: 14 }}
						color={ColorV2.text.neutralHard}
						onClick={props.closeModal}
					/>
				</Header>
				<MobileButtonContainer>
					<Text
						type="h3"
						weight="semibold"
						style={{
							color: ColorV2.text.neutralHard,
							marginBottom: "24px",
							marginLeft: "16px",
						}}
					>
						{props.residenceName}
					</Text>

					<ClickableText
						onClick={() => handleTabClick("Mayores")}
						$isActive={selectedTab === "Mayores"}
					>
						<Text
							type="h5"
							weight="medium"
							style={{
								marginBottom: "11px",
								marginTop: "11px",
								marginLeft: "16px",
								color: ColorV2.text.neutralHard,
							}}
						>
							Mayores
						</Text>
					</ClickableText>
					<ClickableText
						onClick={() => handleTabClick("Cartas")}
						$isActive={selectedTab === "Cartas"}
					>
						<Text
							type="h5"
							weight="medium"
							style={{
								marginBottom: "11px",
								marginTop: "11px",
								marginLeft: "16px",
								color: ColorV2.text.neutralHard,
							}}
						>
							Cartas
						</Text>
					</ClickableText>
					<StyledHr />

					<ClickableText $isActive={false}>
						<Text
							type="p"
							weight="medium"
							style={{
								marginTop: "12px",
								marginLeft: "16px",
								color: ColorV2.text.neutralHard,
							}}
							onClick={handleLogout}
						>
							Cerrar sesión
						</Text>
					</ClickableText>
				</MobileButtonContainer>
			</MainContainer>
		</Modal>
	);
}

interface Props {
	isModalOpen: boolean;
	closeModal: () => void;
	residenceName?: string;
}

export default Homepage;
