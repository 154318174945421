import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
	Text,
	Filter,
	Modal,
	Button,
	ColorV2,
	Pagination,
} from "@adoptaunabuelo/react-components";
import moment from "moment";
import { X, Download, ChevronRight } from "lucide-react";
import { LetterScheme, UserScheme, User } from "client-v2";
import { FilterCheckboxRef } from "@adoptaunabuelo/react-components/dist/cjs/components/Filter/FilterCheckbox";
import animation from "../../assets/animations/button-loading.json";
import TableGenericApp from "../tables/TableGenericApp";
import { Player } from "@lottiefiles/react-lottie-player";
import LetterNamesAnimation from "./LetterNamesAnimation";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import letterHTML from "../../assets/letter/letter";
import stamp_empty from "../../assets/letter/stamp_empty.png";
import stamp_retro from "../../assets/letter/stamp_retro.png";

const ScrollableContainer = styled.div`
	display: block;
	height: 73vh;
	border-radius: 12px;
	overflow-y: auto;
	width: 100%;
	@media (max-width: 1500px) {
		height: 66vh;
	}

	@media (max-width: 768px) {
		height: 74vh;
	}

	@media (max-width: 500px) {
		height: 73vh;
	}

	@media (max-width: 400px) {
		height: 72vh;
	}

	@media (max-width: 380px) {
		height: 64vh;
	}

	@media (max-width: 370px) {
		height: 67vh;
	}
`;

const MainContainer = styled.div`
	background-color: ${ColorV2.surface.invert};
	@media (min-width: 768px) {
		border-radius: 12px;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 8px;
	padding: 12px 0px;

	@media (min-width: 768px) {
		padding: 12px 16px;
	}
`;

const FilterContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const ModalHeader = styled.div`
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 10;
`;

const ModalContainer = styled.div`
	height: 70%;
`;

const ModalButton = styled.div`
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	bottom: 0;
	z-index: 15;
	padding: 16px 0px;
`;

const CloseButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`;

const Divider = styled.div`
	width: 40px;
	height: 4px;
	background-color: #ccc;
	border-radius: 2px;
	margin-bottom: 6px;
	margin-top: 16px;
	margin-left: auto;
	margin-right: auto;
`;

const TopRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const PageContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: ${ColorV2.surface.background};
	justify-content: center;
	padding: 12px 16px;

	@media (max-width: 768px) {
		background-color: ${ColorV2.surface.invert};
	}
`;

const LoadingView = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10000;
`;

const DateAndLetterContainer = styled.div<{ isOptionSelected: boolean }>`
	display: flex;
	flex-direction: ${({ isOptionSelected }) =>
		isOptionSelected ? "row" : "column"};
	${({ isOptionSelected }) =>
		isOptionSelected &&
		`
		justify-content: space-between;
		width: 100%;
	`}
`;

const ColumnsAppContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
`;

const DateAppContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ChevronContainer = styled.div`
	display: flex;
	align-items: center;
`;

function formatDate(createdAt: any): string | null {
	if (!createdAt) {
		console.error("createdAt no encontrado o no válido");
		return null;
	}

	let dateString;

	if (typeof createdAt === "object" && createdAt.iso) {
		dateString = createdAt.iso;
	} else if (typeof createdAt === "string") {
		dateString = createdAt;
	} else {
		console.error("Tipo inesperado de createdAt:", createdAt);
		return null;
	}

	const dateObject = new Date(dateString);

	if (!isNaN(dateObject.getTime())) {
		const day = String(dateObject.getDate()).padStart(2, "0");
		const month = String(dateObject.getMonth() + 1).padStart(2, "0");
		const year = dateObject.getFullYear();

		return `${day}/${month}/${year}`;
	} else {
		console.error("Fecha inválida:", createdAt);
		return null;
	}
}

const LettersTable: React.FC<Props> = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [selectedLetter, setSelectedLetter] = useState<LetterScheme>();
	const [filteredGrandpas, setFilteredGrandpas] = useState<LetterScheme[]>(
		props.letters
	);
	const [sortedGrandpas, setSortedGrandpas] = useState<LetterScheme[]>([]);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);
	const grandpaFilterRef = useRef<FilterCheckboxRef>(null);
	const [isOptionSelected, setIsOptionSelected] = useState(false);
	const rowsPerPage = 50;
	const totalPages = Math.ceil(props.count / rowsPerPage);
	const [currentPage, setCurrentPage] = useState(props.page || 1);
	const dateFilterRef = useRef<FilterCheckboxRef>(null);
	const [sortOrderByDate, setSortOrderByDate] = useState<"recent" | "oldest">(
		"recent"
	);

	const letterTableFilters = useSelector(
		(state: State) => state.letterTableFilters
	);

	const currentUser = useSelector((state: State) => state.currentUser);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		props.onFiltersChange &&
			props.onFiltersChange({ ...letterTableFilters, page: newPage });
	};

	const [selectedOptions, setSelectedOptions] = useState<
		Array<{ id: string; [key: string]: string }>
	>([]);

	const [selectedDateOptions, setSelectedDateOptions] = useState<{
		startDate: moment.Moment | null;
		endDate: moment.Moment | null;
	}>({
		startDate: moment("2024-11-01"),
		endDate: null,
	});

	const [grandpaOptions, setGrandpaOptions] = useState<
		{ id: string; label: string; idGrandpa: string }[]
	>([]);

	const handleDateFilterChange = (selectedOptions: {
		startDate: moment.Moment | null;
		endDate: moment.Moment | null;
	}) => {
		setSelectedDateOptions(selectedOptions);

		props.onFiltersChange &&
			props.onFiltersChange({
				...letterTableFilters,
				createdAt: {
					min: selectedOptions.startDate?.format("YYYY-MM-DD"),
					max: selectedOptions.endDate?.format("YYYY-MM-DD") || null,
				},
				page: 0,
			});
	};

	const handleDefaultFilterChange = (
		options: Array<{ id: string; [key: string]: string }>
	) => {
		setIsOptionSelected(options.length > 0);
		setSelectedOptions(options);

		if (options.length > 0) {
			setFilteredGrandpas(
				props.letters.filter((letter) =>
					options
						.map((option) => option.label)
						.includes(letter.Grandpa?.name || "")
				)
			);

			props.onFiltersChange &&
				props.onFiltersChange({
					...letterTableFilters,
					grandpaId: options[0].idGrandpa,
					page: 0,
				});
		} else {
			setFilteredGrandpas(props.letters);
			props.onFiltersChange &&
				props.onFiltersChange({
					...letterTableFilters,
					grandpaId: null,
					page: 0,
				});
		}
	};

	useEffect(() => {
		if (currentUser?.Residence?.objectId) {
			User.get({
				type: "grandpa",
				residenceId: currentUser?.Residence?.objectId,
				state: ["inRegister", "registered", "match", "waiting"],
				data: { letter: true },
				limit: 500,
			})
				.then((response) => {
					const allGrandpas = response.data;
					const seenGrandpaIds = new Set();

					const options = allGrandpas
						.filter((grandpa) => {
							const grandpaId = grandpa.objectId;

							if (grandpaId && !seenGrandpaIds.has(grandpaId)) {
								seenGrandpaIds.add(grandpaId);
								return true;
							}
							return false;
						})
						.map((grandpa, index) => ({
							id: `grandpa-${index}`,
							label: `${grandpa?.name ?? ""} ${
								grandpa?.surname ?? ""
							}`,
							idGrandpa: grandpa?.objectId,
						}));

					setGrandpaOptions(options);
				})
				.catch((error) => {
					console.error("Error fetching grandpas:", error);
				});
		}
	}, [currentUser?.Residence?.objectId]);

	const handleSortByDate = () => {
		let listToSort = [...filteredGrandpas];
		if (sortOrderByDate === "recent") {
			setSortOrderByDate("oldest");
			listToSort = listToSort.sort((a, b) => {
				const dateA = new Date(
					typeof a.createdAt === "object"
						? a.createdAt.iso
						: a.createdAt
				).getTime();
				const dateB = new Date(
					typeof b.createdAt === "object"
						? b.createdAt.iso
						: b.createdAt
				).getTime();
				return dateA - dateB;
			});
		} else {
			setSortOrderByDate("recent");
			listToSort = listToSort.sort((a, b) => {
				const dateA = new Date(
					typeof a.createdAt === "object"
						? a.createdAt.iso
						: a.createdAt
				).getTime();
				const dateB = new Date(
					typeof b.createdAt === "object"
						? b.createdAt.iso
						: b.createdAt
				).getTime();
				return dateB - dateA;
			});
		}
		setSortedGrandpas(listToSort);
	};

	const filterByProfile = useCallback(() => {

		if (currentPage > totalPages) {
			setCurrentPage(0);
		}

		if (props.abueloData) {
			const initialOption = {
				id: `grandpa-${props.abueloData.name}-${props.abueloData.surname}`,
				label: `${props.abueloData.name} ${props.abueloData.surname}`,
			};
			setSelectedOptions([initialOption]);
			setIsOptionSelected(true);

			props.onFiltersChange &&
				props.onFiltersChange({
					...letterTableFilters,
					createdAt: {
						min:
							selectedDateOptions.startDate?.format(
								"YYYY-MM-DD"
							) || "2024-11-01",
						max:
							selectedDateOptions.endDate?.format("YYYY-MM-DD") ||
							null,
					},
					grandpaId: props.abueloData.objectId,
					page: 0,
				});
		} else {
			props.onFiltersChange &&
				props.onFiltersChange({
					createdAt: {
						min:
							selectedDateOptions.startDate?.format(
								"YYYY-MM-DD"
							) || "2024-11-01",
						max:
							selectedDateOptions.endDate?.format("YYYY-MM-DD") ||
							null,
					},
					grandpaId: null,
					page: 0,
				});
			setFilteredGrandpas(props.letters);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleRowClick = (letter: LetterScheme) => {
		if (isDesktop) {
			props.onSelectLetter(letter);
			setShowModal(false);
		} else {
			setSelectedLetter(letter);
			setShowModal(true);
		}
	};

	const closeModal = () => {
		setShowModal(false);
		setSelectedLetter(undefined);
	};

	const filterByGrandpa = useCallback(() => {
		let filteredList = props.letters;

		if (isOptionSelected && selectedOptions.length > 0) {
			const selectedFullNames = selectedOptions.map(
				(option) => option.label
			);

			filteredList = props.letters.filter((letter) => {
				const grandpaFullName = `${letter.Grandpa?.name || ""} ${
					letter.Grandpa?.surname || ""
				}`;

				return selectedFullNames.includes(grandpaFullName);
			});
		}

		if (selectedDateOptions.startDate || selectedDateOptions.endDate) {
			filteredList = filteredList.filter((letter) => {
				let createdAtString;

				if (typeof letter.createdAt === "string") {
					createdAtString = letter.createdAt;
				} else if (
					typeof letter.createdAt === "object" &&
					letter.createdAt.iso
				) {
					createdAtString = letter.createdAt.iso;
				} else {
					return false;
				}

				const cartaDate = moment(createdAtString);
				const { startDate, endDate } = selectedDateOptions;

				return (
					(!startDate || cartaDate.isSameOrAfter(startDate)) &&
					(!endDate || cartaDate.isSameOrBefore(endDate))
				);
			});
		}
		setFilteredGrandpas(filteredList);
	}, [props.letters, selectedOptions, selectedDateOptions, isOptionSelected]);

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	useEffect(() => {
		setSortedGrandpas([...filteredGrandpas]);
	}, [filteredGrandpas]);

	useEffect(() => {
		filterByProfile();
	}, [filterByProfile]);

	useEffect(() => {
		filterByGrandpa();
	}, [filterByGrandpa]);

	const toBase64 = async (url: string): Promise<string> => {
		const response = await fetch(url);
		const blob = await response.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	};

	const downloadLetter = async () => {
		if (selectedLetter) {
			setLoadingPDF(true);

			let logoBase64 = "";
			if (selectedLetter.Coorporate?.logo) {
				logoBase64 = await toBase64(selectedLetter.Coorporate.logo.url);
			}

			let html = await letterHTML();

			// Replace placeholders in HTML
			html = html.replace("{{content}}", selectedLetter.content);
			html = html.replace(
				"{{name}}",
				`${selectedLetter.User.name} ${selectedLetter.User.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(selectedLetter.User.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					selectedLetter.customization
						? selectedLetter.customization.font
						: "Poppins"
				}";`
			);

			// Replace corporate logo or stamp
			if (selectedLetter.Coorporate) {
				html = html.replace("{{stamp_img}}", stamp_empty);
				html = html.replace(
					"{{corporate_logo}}",
					`<img src="${logoBase64}" />`
				);
			} else {
				html = html.replace("{{stamp_img}}", stamp_retro);
				html = html.replace("{{corporate_logo}}", "");
			}

			// Replace other customization options
			if (selectedLetter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (selectedLetter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (selectedLetter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${selectedLetter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			// Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoadingPDF(false);
				});
		}
	};

	const columnsLetter: any = !isDesktop
		? [
				{
					render: (letter: LetterScheme) => (
						<ColumnsAppContainer>
							<DateAndLetterContainer
								isOptionSelected={isOptionSelected}
							>
								<LetterNamesAnimation
									letter={letter}
									isOptionSelected={isOptionSelected}
								/>

								<DateAppContainer>
									<Text
										type="c1"
										weight="regular"
										style={{
											color: ColorV2.text.neutralMedium,
											marginRight: "4px",
										}}
									>
										{!isOptionSelected
											? `Enviada el ${formatDate(
													letter.createdAt
											  )}`
											: formatDate(letter.createdAt)}
									</Text>
								</DateAppContainer>
							</DateAndLetterContainer>

							<ChevronContainer>
								<ChevronRight
									size={20}
									color={ColorV2.border.neutralMedium}
								/>
							</ChevronContainer>
						</ColumnsAppContainer>
					),
				},
		  ]
		: [
				{
					header: "Remitente",
					key: "remitente",
					render: (letter: LetterScheme) => (
						<Text
							type="p"
							weight="regular"
							style={{
								color: ColorV2.text.neutralHard,
								marginRight: "8px",
							}}
						>
							{letter.User.name} {letter.User.surname}
						</Text>
					),
				},
				{
					header: "Destinatario (Abuelo)",
					key: "destinatario",
					render: (letter: LetterScheme) => (
						<Text
							type="p"
							weight="regular"
							style={{
								color: ColorV2.text.neutralHard,
								marginRight: "8px",
							}}
						>
							{letter.Grandpa?.name} {letter.Grandpa?.surname}
						</Text>
					),
				},
				{
					header: "Fecha",
					key: "fecha",
					icon: require("../../assets/images/arrow-up.png"),
					onClick: () => handleSortByDate(),
					render: (letter: LetterScheme) => (
						<Text
							type="p2"
							weight="regular"
							style={{
								color: ColorV2.text.neutralHard,
								marginRight: "4px",
							}}
						>
							{formatDate(letter.createdAt)}
						</Text>
					),
				},
		  ];

	return (
		<>
			<MainContainer>
				{props.loading && (
					<LoadingView>
						<Player
							style={{ height: 150, width: 150 }}
							loop={true}
							autoplay={true}
							src={animation}
						/>
					</LoadingView>
				)}
				<HeaderContainer>
					<TopRow>
						<Text
							type={isDesktop ? "h4" : "h1"}
							weight="semibold"
							style={{
								color: ColorV2.text.neutralHard,
								lineHeight: "36px",
							}}
						>
							{props.count} cartas
						</Text>
					</TopRow>
					<FilterContainer>
						<Filter
							id="GrandpaFilter"
							placeholder={
								isDesktop ? "Dest. (abuelo)" : "Abuelo"
							}
							options={
								grandpaOptions?.map((option) => ({
									...option,
									label: option.label || "",
									idGrandpa: option.idGrandpa || "",
								})) || []
							}
							shape="circle"
							type="single"
							selectedOptions={selectedOptions}
							onChange={handleDefaultFilterChange}
							ref={grandpaFilterRef}
						/>
						<Filter
							id="DateFilter"
							placeholder="Fecha"
							type="date"
							selectedOptions={selectedDateOptions}
							onChange={handleDateFilterChange}
							ref={dateFilterRef}
						/>
					</FilterContainer>
				</HeaderContainer>

				<ScrollableContainer>
					<TableGenericApp
						columns={columnsLetter}
						data={sortedGrandpas}
						onRowClick={(letter: LetterScheme) => {
							handleRowClick(letter);
						}}
						showAvatar={false}
						noResultsMessage="No hay cartas que coincidan con tu búsqueda"
					/>
				</ScrollableContainer>

				{/*
				<MobileButtonContainer>
					<FixedButton
						type="b2"
						weight="medium"
						style={{
							padding: isDesktop ? "10px 20px" : "15px 20px",
							margin: isDesktop
								? "10px 20px"
								: "15px 0px 15px 0px",
							borderRadius: "30px",
							backgroundColor: ColorV2.surface.primary,
							color: ColorV2.text.invert,
						}}
					>
						<span
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								fontSize: isDesktop ? "14px" : "16px",
							}}
						>
							<Download
								size={20}
								style={{ marginRight: "8px" }}
							/>
							Descargar una de cada
						</span>
						
					</FixedButton>
				</MobileButtonContainer>
				*/}
				{showModal && selectedLetter && (
					<Modal
						type="full-screen"
						isVisible={showModal}
						onClose={closeModal}
						hideHeader={true}
						hideClose={true}
						shouldCloseOnOverlayClick={true}
					>
						<>
							<ModalHeader>
								<Divider />
								<CloseButton
									onClick={closeModal}
									style={{
										paddingLeft: "0px",
										paddingTop: "14px",
									}}
								>
									<X size={24} />
								</CloseButton>
								<Text
									type="p"
									weight="regular"
									style={{
										color: ColorV2.text.neutralHard,
										marginTop: "8px",
									}}
								>
									De {selectedLetter.User.name}{" "}
									{selectedLetter.User.surname}{" "}
								</Text>
								<Text
									type="p2"
									weight="regular"
									style={{
										color: ColorV2.text.neutralMedium,
										marginBottom: "24px",
									}}
								>
									Enviada el{" "}
									{formatDate(selectedLetter.createdAt)}
								</Text>
							</ModalHeader>
							<ModalContainer>
								<Text
									type="p2"
									weight="regular"
									style={{
										color: ColorV2.text.neutralHard,
									}}
								>
									{selectedLetter.content}
								</Text>
							</ModalContainer>
							<ModalButton>
								<Button
									design="primary"
									icon={<Download size={20} />}
									style={{ width: "100%" }}
									loading={loadingPDF}
									onClick={downloadLetter}
								>
									Descargar carta
								</Button>
							</ModalButton>
						</>
					</Modal>
				)}
			</MainContainer>

			<PageContainer>
				<Pagination
					start={currentPage}
					length={props.count}
					rowsPerPage={rowsPerPage}
					onChange={handlePageChange}
				/>
			</PageContainer>
		</>
	);
};

interface Props {
	loading?: boolean;
	page: number;
	count: number;
	letters: LetterScheme[];
	onSelectLetter: (letter: LetterScheme) => void;
	abueloData: UserScheme | undefined;
	onFiltersChange: (updatedFilters: any) => void;
}

export default LettersTable;
