// ErrorMessage.tsx
import React from "react";
import styled from "styled-components";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import { Info } from "lucide-react";

const StyledErrorMessage = styled.div`
	display: flex;
	padding: 12px 16px;
	align-items: center;
	margin-top: 16px;
	gap: 12px;
	align-self: stretch;
	border-radius: var(--radius-L, 12px);
	background: ${ColorV2.surface.redSoft};
	color: ${ColorV2.text.red};
	font-family: Poppins;
	font-size: 15px;
	flex: 1 0 0;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

const ErrorMessage: React.FC<Props> = (props) => (
	<StyledErrorMessage>
		<Text type="c1" weight="regular" style={{ color: ColorV2.text.red }}>
			{props.message}
		</Text>

		<Info size="20" color={ColorV2.surface.red} />
	</StyledErrorMessage>
);

interface Props {
	message: string;
}

export default ErrorMessage;
