import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	ColorV2,
	Avatar,
	Text,
} from "@adoptaunabuelo/react-components";

const AvatarContainer = styled.div`
	width: 50px;
	flex-shrink: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media (min-width: 768px) {
		margin-left: 12px;
	}
`;

const ImageStyled = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 1000px;
`;

const StyledTable = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
`;

const StyledTdAvatar = styled.td`
	width: 50px;
	text-align: left;
	border-bottom: none;
`;

const ScrollableTBody = styled.tbody`
	display: block;
	width: 100%;
	overflow-y: auto;
`;

const ScrollableTr = styled.tr`
	display: table;
	width: 100%;
	table-layout: fixed;
	cursor: pointer;
	&:hover {
		background: #f0f0f0;
	}
`;

const StyledThead = styled.thead`
	position: sticky;
	top: 0;
	background-color: ${ColorV2.surface.invert};
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	z-index: 1;
	display: table;
	width: 100%;
	table-layout: fixed;
`;

const StyledTr = styled.tr`
	height: 48px;
	padding: 8px 0px;
`;

const StyledTd = styled.td<{ $columnKey?: string }>`
	padding: 16px;
	padding-right: 0px;
	margin-right: 40px;
	border-bottom: none;
	width: auto;
	padding-left: 16px;

	@media (max-width: 768px) {
		padding-left: 0px;
		border-bottom: 1px solid ${ColorV2.border.neutralSoft};
		width: ${({ $columnKey }) => ($columnKey === "Nombre" ? "75%" : "25%")};
	}
`;

type TableColumn = {
	key: string;
	header: string;
	align?: string;
	marginRight?: string;
	flexSize?: number;
	render?: (item: any) => React.ReactNode;
	icon?: string;
	onClick?: () => void;
};

interface Props {
	columns: TableColumn[];
	count?: number;
	secondaryCount?: number;
	secondaryTitle?: string;
	page?: number;
	data: any[];
	onRowClick: (rowData: any) => void;
	noResultsMessage?: string;
	showAvatar?: boolean;
}

const StandardTable: React.FC<Props> = (props) => {

	const { showAvatar= true, ...restProps } = props;

	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	

	return (
			<StyledTable>
				{isDesktop && restProps.data.length > 0 && (
					<StyledThead>
						<StyledTr>
							{showAvatar && <StyledTdAvatar></StyledTdAvatar>}
							{restProps.columns.map((column, colIndex) => (
								<StyledTd key={colIndex}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-start",
										}}
									>
										<Text
											type="c1"
											weight="regular"
											style={{
												color: ColorV2.text.neutralMedium,
											}}
										>
											{column.header}
										</Text>
										{column.icon && (
											<img
												src={column.icon}
												alt="arrowUp"
												height={16}
												width={16}
												style={{
													marginLeft: "8px",
													cursor: "pointer",
												}}
												onClick={(e) => {
													e.stopPropagation();
													if (column.onClick) {
														column.onClick();
													}
												}}
											/>
										)}
									</div>
								</StyledTd>
							))}
						</StyledTr>
					</StyledThead>
				)}

				<ScrollableTBody>
					{restProps.data.length > 0 ? (
						restProps.data.map((item, index) => (
							<ScrollableTr
								key={index}
								onClick={() => restProps.onRowClick(item)}
							>
								{showAvatar && (
									<StyledTdAvatar>
										<AvatarContainer>
											{item.image ? (
												<ImageStyled src={item.image.url} />
											) : isDesktop ? (
												<img
													height={40}
													width={40}
													alt="figure"
													src={require("../../assets/images/figure.png")}
												/>
											) : (
												<Avatar
													name={item.name}
													style={{
														height: 40,
														width: 40,
														fontSize: 15,
														color: ColorV2.text.invert,
														backgroundColor:
															ColorV2.surface
																.neutralMedium,
													}}
												/>
											)}
										</AvatarContainer>
									</StyledTdAvatar>
								)}

								{restProps.columns.map((column, colIndex) => (
									<StyledTd
										key={colIndex}
										$columnKey={column.key}
									>
										{column.render
											? column.render(item)
											: item[column.key]}
									</StyledTd>
								))}
							</ScrollableTr>
						))
					) : (
						<ScrollableTr>
							<StyledTd
								colSpan={restProps.columns.length + (showAvatar ? 1 : 0)}
							>
								<Text
									type="h6"
									weight="regular"
									style={{
										textAlign: "center",
										marginTop: "40px",
										color: ColorV2.text.neutralMedium,
									}}
								>
									{restProps.noResultsMessage}
								</Text>
							</StyledTd>
						</ScrollableTr>
					)}

					
				</ScrollableTBody>
			</StyledTable>
			
	);
};

export default StandardTable;
