import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
	Text,
	Avatar,
	Modal,
	ColorV2,
	Checkbox,
	TagSelector,
	Feedback,
} from "@adoptaunabuelo/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { shutdownMotivations } from "../../../data/shutdownMotivations";
import { State } from "../../../redux";
import { useSelector } from "react-redux";
import { Constant, Grandpa, UserScheme, Letter } from "client-v2";
import ViewContainer from "../../../components/container/ViewContainer";
import { Player } from "@lottiefiles/react-lottie-player";
import animation from "../../../assets/animations/button-loading.json";
import LettersBox from "../../../components/LettersBox";
import { Ellipsis, Pencil, Trash } from "lucide-react";

const ProfileContainer = styled.div`
	height: 600px;
	margin: 24px;
	margin-top: 0px;
	border-radius: 12px;
	background: #fff;
`;

const LoadingView = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000000;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: flex-start;
	width: 100%;
`;

const InfoContainer = styled.div`
	text-align: left;
	margin-bottom: 24px;
`;

const DescriptionContainer = styled.div`
	text-align: left;
`;

const LabelContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const StyledImg = styled.img`
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

const AdoptionInfo = styled.div`
	display: flex;
	align-items: center;
	margin-top: 4px;
`;

const ImageHeader = styled.img`
	height: 88px;
	width: 88px;
	margin-right: 12px;
	margin-bottom: 24px;
	border-radius: 1000px;
`;

const ModalContent = styled.div`
	position: relative;
	padding-top: 12px;
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	transition: transform 0.5s ease-in-out;
`;

const StyledButton = styled.button`
	display: flex;
	padding: 15px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 100%;
	border-radius: 1000px;
	background: rgba(0, 29, 61, 0.04);
	border: none;
	transition: transform 0.3s ease-in-out;
	cursor: pointer;

	&:active {
		transform: scale(0.95);
	}
`;

const CheckBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	padding: 12px 16px;
	background-color: ${ColorV2.surface.invert};
	margin-bottom: 24px;
`;

const StyledDivEdit = styled.div`
	display: flex;
	margin-top: 8px;
	padding: 15px 16px;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	cursor: pointer;
`;

const StyledDivDown = styled.div`
	display: flex;
	margin-bottom: 16px;
	padding: 15px 16px;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	cursor: pointer;
`;

const Divider = styled.div`
	width: 40px;
	height: 4px;
	background-color: #ccc;
	border-radius: 2px;
	margin-bottom: 24px;
	margin-left: auto;
	margin-right: auto;
`;

const StyledModalContent = styled.div`
	padding-top: 12px;
	margin-bottom: 16px;
	border-radius: 30px 30px 0 0;
	display: flex;
	flex-direction: column;
`;

const StyledButton2 = styled.button`
	display: flex;
	padding: 15px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 100%;
	border-radius: var(--radius-Full, 1000px);
	transition: transform 0.3s ease-in-out;
	text-transform: none;
	margin-top: 8px;
	border: none;
	background: none;
	cursor: pointer;

	&:active {
		transform: scale(0.95);
	}
`;

const StyledButtonUnsubscribe = styled(StyledButton2)`
	background: ${ColorV2.surface.red};
	color: #fff;
	align-self: stretch;

	&:hover {
		background-color: #c02626;
	}
`;

const ProtectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 12px;
	margin-top: 40px;
	padding-bottom: 24px;
`;

const DescriptionLetters = styled.div`
	margin-bottom: 40px;
`;

const calculateTimeSince = (date: Date) => {
	const today = new Date();
	let years = today.getFullYear() - date.getFullYear();
	let months = today.getMonth() - date.getMonth();
	let days = today.getDate() - date.getDate();

	if (months < 0) {
		years--;
		months += 12;
	}

	if (days < 0) {
		months--;
		const lastMonth = new Date(
			today.getFullYear(),
			today.getMonth(),
			0
		).getDate();
		days += lastMonth;
	}

	return { years, months, days };
};

const calculateAge = (birthDate: Date) => {
	const today = new Date();
	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDiff = today.getMonth() - birthDate.getMonth();

	if (
		monthDiff < 0 ||
		(monthDiff === 0 && today.getDate() < birthDate.getDate())
	) {
		age--;
	}

	return age;
};

const formatDate = (date: Date) => {
	const day = date.getDate();
	const month = new Intl.DateTimeFormat("es", { month: "short" }).format(
		date
	);
	const year = date.getFullYear();
	return `${day} ${month} ${year}`;
};

const ProfileGrandpa: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isModalProfileOpen, setModalProfileOpen] = useState(false);
	const [isModalUnsubscribeOpen, setisModalUnsubscribeOpen] = useState(false);
	const grandpaInfo: UserScheme = location.state?.grandpaInfo;
	const [loading, setLoading] = useState(false);

	const birthDate = grandpaInfo?.birthday?.iso
		? new Date(grandpaInfo.birthday.iso)
		: null;
	const age = birthDate ? calculateAge(birthDate) : null;
	const formattedDate = birthDate ? formatDate(birthDate) : null;

	const adoptionDate = new Date(grandpaInfo.createdAt);
	const { years, months, days } = calculateTimeSince(adoptionDate);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const currentUser = useSelector((state: State) => state.currentUser);

	const [cardCount, setCardCount] = useState(0);

	const [checkboxMotivation, setCheckboxMotivation] = useState<{
		id: string;
		label: string;
	} | null>(null);

	const motivationsList = shutdownMotivations().motivations;

	const onOptionChange = (selectedOptionId: string) => {
		const selectedMotivation = motivationsList.find(
			(motivation) => motivation.id === selectedOptionId
		);
		if (selectedMotivation) {
			setCheckboxMotivation(selectedMotivation);
		} else {
			console.error("Motivation not found for id:", selectedOptionId);
		}
	};

	const fetchCardCount = useCallback(() => {
		setLoading(true);
		if (grandpaInfo && grandpaInfo.objectId) {
			Letter.getCountForGrandpa(grandpaInfo.objectId)
				.then((result) => {
					setCardCount(result.data);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.error(
						"Error al obtener el conteo de cartas: ",
						error
					);
				});
		}
	}, [grandpaInfo]);

	useEffect(() => {
		fetchCardCount();
	}, [fetchCardCount]);

	const timeSinceAdoption = () => {
		if (years > 0) {
			return `Registrado hace ${years} ${years === 1 ? "año" : "años"}`;
		} else if (months > 0) {
			return `Registrado hace ${months} ${
				months === 1 ? "mes" : "meses"
			}`;
		} else if (days > 0) {
			return `Registrado hace ${days} ${days === 1 ? "día" : "días"}`;
		} else {
			return "Registrado hace menos de un día";
		}
	};

	const foundHobbies =
		grandpaInfo.data?.hobbies && grandpaInfo.data.hobbies.length > 0
			? Constant.hobbies().filter((hobby) =>
					grandpaInfo.data?.hobbies?.includes(hobby.id)
			  )
			: [];

	const foundLanguages =
		grandpaInfo.data?.languages && grandpaInfo.data.languages.length > 0
			? Constant.languages().filter((language) =>
					grandpaInfo.data?.languages?.includes(language.id)
			  )
			: [];

	if (!grandpaInfo) {
		return (
			<Text type="p2" style={{ color: ColorV2.text.neutralMedium }}>
				No hay información del abuelo disponible.
			</Text>
		);
	}

	const handleOpenModal = () => {
		setModalProfileOpen(true);
		setisModalUnsubscribeOpen(false);
	};

	const handleCloseModalProfile = () => {
		setModalProfileOpen(false);
	};

	const handleUnsubscribeOpen = () => {
		setisModalUnsubscribeOpen(true);
		setModalProfileOpen(false);
	};

	const handleUnsubscribeClose = () => {
		setisModalUnsubscribeOpen(false);
		setModalProfileOpen(false);
	};

	const handleUnsubscribe = () => {
		if (currentUser) {
			if (checkboxMotivation) {
				Grandpa.remove(grandpaInfo.objectId, {
					motivation: checkboxMotivation,
					adminId: currentUser.objectId,
				});

				navigate("/abuelos/unsubscribe-screen");
			} else {
				setErrorMessage("Debes seleccionar un motivo");
				console.error(
					"Motivation is null, cannot proceed with grandpaInfo.remove"
				);
			}
		} else {
			console.error("currentUser es null");
		}
	};

	const handleEditProfile = () => {
		navigate("/abuelos/registro", { state: { grandpaInfo } });
	};

	const handleNavigateToLetters = () => {
		if (cardCount === 0) {
			setErrorMessage("No hay cartas para mostrar");
		} else {
			navigate("/cartas", { state: { grandpaInfo } });
			setErrorMessage(null);
		}
	};

	return (
		<>
			{loading && (
				<LoadingView>
					<Player
						style={{ height: 150, width: 150 }}
						loop={true}
						autoplay={true}
						src={animation}
					/>
				</LoadingView>
			)}
			<Modal
				type="full-screen"
				isVisible={isModalProfileOpen}
				hideHeader={true}
				shouldCloseOnOverlayClick={true}
				onClose={handleCloseModalProfile}
				hideClose={true}
			>
				<ModalContent onClick={(e) => e.stopPropagation()}>
					<Divider />

					<>
						<StyledDivEdit onClick={handleEditProfile}>
							<Pencil
								size={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text
								type="p"
								weight="medium"
								style={{
									color: ColorV2.text.neutralHard,
								}}
							>
								Editar Perfil
							</Text>
						</StyledDivEdit>

						<StyledDivDown onClick={handleUnsubscribeOpen}>
							<Trash size={24} color={ColorV2.text.red} />
							<Text
								type="p"
								weight="medium"
								style={{
									color: ColorV2.text.red,
								}}
							>
								Dar de baja
							</Text>
						</StyledDivDown>
					</>

					<StyledButton onClick={handleCloseModalProfile}>
						<Text
							type="b1"
							weight="semibold"
							style={{
								textAlign: "center",
								color: ColorV2.text.neutralHard,
							}}
						>
							Cancelar
						</Text>
					</StyledButton>
				</ModalContent>
			</Modal>
			<Modal
				type="full-screen"
				isVisible={isModalUnsubscribeOpen}
				hideHeader={true}
				style={{ backgroundColor: ColorV2.surface.background }}
				onClose={handleUnsubscribeClose}
				shouldCloseOnOverlayClick={true}
				hideClose={true}
			>
				<StyledModalContent onClick={(e) => e.stopPropagation()}>
					<Divider />

					<Text
						type="h5"
						weight="semibold"
						style={{
							marginBottom: "12px",
						}}
					>
						¿Por qué quieres dar de baja al abuelo?
					</Text>

					<Text
						type="p2"
						weight="regular"
						style={{
							marginBottom: "24px",
						}}
					>
						Queremos saber en qué podemos mejorar.
					</Text>

					<CheckBoxContainer>
						<Checkbox
							style={{ width: "100%", borderRadius: "1000px" }}
							options={motivationsList}
							selectedOptions={
								checkboxMotivation
									? [{ id: checkboxMotivation.id }]
									: []
							}
							shape="circle"
							type={"single"}
							position={"right"}
							onChange={(selectedOptions: { id: string }[]) => {
								if (selectedOptions.length > 0) {
									onOptionChange(selectedOptions[0].id);
								}
							}}
						/>
					</CheckBoxContainer>

					<Feedback
						style={{ bottom: 60 }}
						type="error"
						isVisible={errorMessage ? true : false}
						text={errorMessage ? errorMessage : ""}
						onClose={() => setErrorMessage(null)}
					/>

					<StyledButtonUnsubscribe onClick={handleUnsubscribe}>
						<Text
							type="b1"
							weight="semibold"
							style={{ color: "white" }}
						>
							Dar de baja
						</Text>
					</StyledButtonUnsubscribe>
				</StyledModalContent>
			</Modal>
			<ViewContainer
				$NabBarButton={
					<Ellipsis
						size={24}
						onClick={handleOpenModal}
						style={{ padding: "14px" }}
						color={ColorV2.text.neutralHard}
					/>
				}
				$onBackClick={() => navigate("/abuelos")}
			>
				<ProfileContainer>
					<HeaderContainer>
						{grandpaInfo.image ? (
							<ImageHeader src={grandpaInfo.image.url} />
						) : (
							<Avatar
								name={grandpaInfo.name}
								style={{
									height: 88,
									width: 88,
									fontSize: 32,
									marginBottom: 24,
									marginRight: 12,
								}}
							/>
						)}
						<InfoContainer>
							<Text type="h3" weight="semibold">
								{grandpaInfo.name} {grandpaInfo.surname}
							</Text>

							<Text type="h6" weight="medium">
								{age && formattedDate
									? `${age} años - ${formattedDate}`
									: "Fecha no especificada"}
							</Text>
							<AdoptionInfo>
								<StyledImg
									src={require("../../../assets/images/radio.check.png")}
								/>
								<Text type="c1" weight="regular">
									{timeSinceAdoption()}
								</Text>
							</AdoptionInfo>
						</InfoContainer>
					</HeaderContainer>

					<DescriptionLetters onClick={handleNavigateToLetters}>
						<LettersBox
							title={cardCount}
							subtitle="Desde hace dos años"
						/>
					</DescriptionLetters>

					<DescriptionContainer>
						<Text
							type="h6"
							weight="medium"
							style={{
								marginBottom: "16px",
							}}
						>
							Aficiones
						</Text>
						<LabelContainer>
							{foundHobbies.length > 0 ? (
								<TagSelector
									onlyVisual={true}
									design="design2"
									options={foundHobbies.map((hobby) => ({
										...hobby,
										subtitle: "",
									}))}
								/>
							) : (
								<Text
									type="p2"
									style={{
										color: ColorV2.text.neutralMedium,
									}}
								>
									No se encontraron hobbies.
								</Text>
							)}
						</LabelContainer>

						<Text
							type="h6"
							weight="medium"
							style={{
								marginTop: "32px",
								marginBottom: "16px",
							}}
						>
							Idiomas que habla
						</Text>
						<LabelContainer>
							{foundLanguages.length > 0 ? (
								<TagSelector
									onlyVisual={true}
									design="design2"
									options={foundLanguages.map((hobby) => ({
										...hobby,
										subtitle: "",
									}))}
								/>
							) : (
								<Text
									type="p2"
									style={{
										color: ColorV2.text.neutralMedium,
									}}
								>
									No se encontraron idiomas.
								</Text>
							)}
						</LabelContainer>
					</DescriptionContainer>

					<ProtectionContainer>
						<Text
							type="c2"
							weight="regular"
							style={{ color: ColorV2.text.neutralMedium }}
						>
							Para proteger tanto tus datos como los del abuelo,
							es importante que te comuniques solo a través de
							nuestra aplicación.
						</Text>

						<img
							src={require("../../../assets/images/lock.png")}
							alt="Imagen Candado"
							width={32}
							height={32}
						/>
					</ProtectionContainer>
					<Feedback
						type="error"
						isVisible={errorMessage ? true : false}
						text={errorMessage ? errorMessage : ""}
						onClose={() => setErrorMessage(null)}
					/>
				</ProfileContainer>
			</ViewContainer>
		</>
	);
};

export default ProfileGrandpa;
