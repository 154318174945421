import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Search } from "lucide-react";
import { Text, Input, ColorV2 } from "@adoptaunabuelo/react-components";
import { useNavigate } from "react-router-dom";
import { UserScheme } from "client-v2";
import moment from "moment";
import TableGenericApp from "../tables/TableGenericApp";
import { ChevronRight, Plus } from "lucide-react";
import { Player } from "@lottiefiles/react-lottie-player";
import animation from "../../assets/animations/button-loading.json";

const MainContainer = styled.div`
	background-color: ${ColorV2.surface.invert};
	height: 100%;
	min-width: auto;
	@media (min-width: 768px) {
		min-width: 740px;
		border-radius: 12px;
	}
`;

const ScrollableContainer = styled.div<{ $isSearchFocused: boolean }>`
	max-height: ${({ $isSearchFocused }) =>
		$isSearchFocused ? "84vh" : "79vh"};
	height: 80vh;
	overflow-y: auto;

	@media (max-width: 1500px) {
		height: 75vh;
	}

	@media (max-width: 768px) {
		height: 75vh;
	}

	@media (max-width: 500px) {
		height: 77vh;
	}

	@media (max-width: 400px) {
		height: 74vh;
	}

	@media (max-width: 380px) {
		height: 70vh;
	}

	@media (max-width: 350px) {
		height: 75vh;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px 0px;
	@media (min-width: 768px) {
		padding: 12px 16px;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
	}
`;

const FixedButton = styled(Text)`
	cursor: pointer;
	border-radius: 30px;
	background-color: ${ColorV2.surface.primary};

	@media (min-width: 768px) {
		position: relative;
		margin-left: auto;
	}

	@media (max-width: 767px) {
		position: fixed;
		cursor: pointer;
		bottom: 20px;
		right: 20px;
	}
`;

const ButtonAddContainer = styled.span`
	display: flex;
	align-items: center;
	color: ${ColorV2.text.invert};
	font-size: 16px;
	padding: 15px 20px;

	@media (min-width: 768px) {
		padding: 10px 20px;
		font-size: 14px;
	}
`;

const AgeColumnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (min-width: 768px) {
		justify-content: flex-start;
	}
`;

const NameColumnContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const SearchContainer = styled.div<{ $isSearchFocused: boolean }>`
	width: 100%;
	position: relative;

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	@media (max-width: 767px) {
		display: ${({ $isSearchFocused }) =>
			$isSearchFocused ? "block" : "block"};
	}
`;

const LoadingView = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000000;
`;

const GrandpasTable: React.FC<Props> = (props) => {
	const navigate = useNavigate();

	const [sortOrderByName, setSortOrderByName] = useState<
		"added" | "asc" | "desc"
	>("asc");
	const [sortOrderByAge, setSortOrderByAge] = useState<
		"added" | "asc" | "desc"
	>("added");

	const [searchTerm, setSearchTerm] = useState("");
	const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);
	const [isDesktop, setIsDesktop] = useState(false);

	const handleSortByName = () => {
		if (sortOrderByName === "added") {
			setSortOrderByName("asc");
		} else if (sortOrderByName === "asc") {
			setSortOrderByName("desc");
		} else {
			setSortOrderByName("added");
		}
		setSortOrderByAge("added");
	};

	const handleSortByAge = () => {
		if (sortOrderByAge === "added") {
			setSortOrderByAge("asc");
		} else if (sortOrderByAge === "asc") {
			setSortOrderByAge("desc");
		} else {
			setSortOrderByAge("added");
		}
		setSortOrderByName("added");
	};

	const handleFocus = () => {
		setIsSearchFocused(true);
		if (props.onSearchFocusChange) {
			props.onSearchFocusChange(true);
		}
	};

	const handleCancel = () => {
		setSearchTerm("");
		setIsSearchFocused(false);
		if (props.onSearchFocusChange) {
			props.onSearchFocusChange(false);
		}
	};

	let filteredGrandpas = props.grandpas.filter((grandpa) =>
		grandpa.name && grandpa.surname
			? (grandpa.name + " " + grandpa.surname)
					.toLowerCase()
					.includes(searchTerm.toLowerCase())
			: false
	);

	if (sortOrderByName !== "added") {
		filteredGrandpas = filteredGrandpas.sort((a, b) => {
			const fullNameA = `${a.name} ${a.surname}`.toLowerCase();
			const fullNameB = `${b.name} ${b.surname}`.toLowerCase();

			if (sortOrderByName === "asc") {
				return fullNameA.localeCompare(fullNameB);
			} else {
				return fullNameB.localeCompare(fullNameA);
			}
		});
	}

	if (sortOrderByAge !== "added") {
		filteredGrandpas = filteredGrandpas.sort((a, b) => {
			const ageA = moment().diff(moment(a.birthday?.iso), "years");
			const ageB = moment().diff(moment(b.birthday?.iso), "years");

			return sortOrderByAge === "asc" ? ageB - ageA : ageA - ageB;
		});
	}

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	const columnsGrandpa = [
		{
			header: "Nombre",
			key: "Nombre",
			align: "flex-start",
			icon: require("../../assets/images/arrow-up.png"),
			onClick: () => handleSortByName(),
			render: (grandpa: UserScheme) => (
				<NameColumnContainer>
					<Text
						type="p"
						weight="regular"
						style={{
							color: ColorV2.text.neutralHard,
						}}
					>
						{grandpa.name} {grandpa.surname}
					</Text>
				</NameColumnContainer>
			),
		},
		{
			header: "Edad",
			key: "Edad",
			align: "flex-start",
			marginRight: "40px",
			icon: require("../../assets/images/arrow-up.png"),
			onClick: () => handleSortByAge(),
			render: (grandpa: UserScheme) => (
				<AgeColumnContainer>
					<Text
						type={isDesktop ? "p2" : "c1"}
						weight="regular"
						style={{
							color: isDesktop
								? ColorV2.text.neutralHard
								: ColorV2.text.neutralMedium,
						}}
					>
						{moment().diff(moment(grandpa.birthday?.iso), "year")}{" "}
						años
					</Text>
					{!isDesktop && (
						<ChevronRight
							size={20}
							color={ColorV2.border.neutralMedium}
						/>
					)}
				</AgeColumnContainer>
			),
		},
	];

	return (
		<MainContainer>
			{props.loading && (
				<LoadingView>
					<Player
						style={{ height: 150, width: 150 }}
						loop={true}
						autoplay={true}
						src={animation}
					/>
				</LoadingView>
			)}

			<HeaderContainer>
				{(!isSearchFocused || isDesktop) && (
					<>
						<Text
							type={isDesktop ? "h4" : "h1"}
							weight="semibold"
							style={{
								color: ColorV2.text.neutralHard,
								width: isDesktop ? "200px" : undefined,
							}}
						>
							{filteredGrandpas.length} mayores
						</Text>
					</>
				)}

				<SearchContainer $isSearchFocused={isSearchFocused}>
					<Input
						type="text"
						icon={
							<Search
								role="icon"
								width={20}
								height={20}
								color={ColorV2.surface.neutralHigh}
							/>
						}
						placeholder="Buscar por nombre"
						design="primary"
						style={{
							borderRadius: "1000px",
							width: isDesktop
								? "300px"
								: isSearchFocused
								? "70%"
								: "90%",
							transition: "width 0.3s ease",
						}}
						onFocus={handleFocus}
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>

					{((!isDesktop && isSearchFocused) ||
						(isDesktop && searchTerm.length > 0)) && (
						<Text
							type="b1"
							weight="medium"
							style={{
								marginLeft: isDesktop ? "16px" : 0,
								position: isDesktop ? "static" : "absolute",
								right: isDesktop ? "auto" : 0,
								top: isDesktop ? "auto" : "50%",
								transform: isDesktop
									? "added"
									: "translateY(-50%)",
								cursor: "pointer",
								color: ColorV2.text.neutralHard,
							}}
							onClick={handleCancel}
						>
							Cancelar
						</Text>
					)}

					<FixedButton
						type="b2"
						weight="medium"
						onClick={() => navigate("/abuelos/registro")}
						style={{
							marginLeft: "auto",
						}}
					>
						<ButtonAddContainer>
							<Plus
								size={20}
								style={{ marginRight: 8 }}
								color={ColorV2.text.invert}
							/>
							{isDesktop ? "Añadir abuelo" : "Añadir"}
						</ButtonAddContainer>
					</FixedButton>
				</SearchContainer>
			</HeaderContainer>

			<ScrollableContainer $isSearchFocused={isSearchFocused}>
				<TableGenericApp
					columns={columnsGrandpa}
					data={filteredGrandpas}
					onRowClick={(grandpa: UserScheme) => {
						props.onSelectGrandpa(grandpa);
					}}
					noResultsMessage="No hay abuelos que coincidan con tu búsqueda"
				/>
			</ScrollableContainer>
		</MainContainer>
	);
};

interface Props {
	loading?: boolean;
	grandpas: UserScheme[];
	onSelectGrandpa: (grandpa: UserScheme) => void;
	onSearchFocusChange?: (isFocused: boolean) => void;
}

export default GrandpasTable;
