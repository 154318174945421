import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
	Button,
	Text,
	Input,
	CellSelector,
	TagSelector,
	ColorV2,
	Feedback,
} from "@adoptaunabuelo/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Grandpa, User, Residence, ResidenceScheme, Constant } from "client-v2";
import { X } from "lucide-react";

const Header = styled.header`
	display: flex;
	padding: 8px 16px;
	justify-content: flex-start;
	align-items: center;
	align-self: stretch;
	position: fixed;
	top: 0;
	z-index: 1000;
	position: relative;
`;

const StyledButtonFooter = styled(Button)`
	display: flex;
	width: 100%;
	padding: 15px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: var(--radius-Full, 1000px);
	background: ${ColorV2.surface.primary};
	transition: transform 0.3s ease-in-out;
	text-transform: none;

	&:active {
		transform: scale(0.95);
	}
`;

const CenteredContainer = styled.div`
	display: flex;
	padding: 0px 24px 24px 24px;
	flex-direction: column;
	gap: 40px;
	overflow: auto;
	flex: 1 0 0;

	align-self: stretch;
`;

const ParentContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${ColorV2.surface.background};
	height: 100vh;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	background-color: ${ColorV2.surface.background};
	width: 100%;
	height: 100vh;

	@media (min-width: 768px) {
		background-color: ${ColorV2.surface.invert};
		width: 50%;
		height: 80vh;
	}
`;

const CenteredText = styled(Text)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

const Footer = styled.footer`
	display: flex;
	padding: 16px 24px;
	justify-content: flex-start;
	align-items: center;
	align-self: stretch;
	position: fixed;
	bottom: 0;
	z-index: 1000;
	position: relative;
`;

const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: flex-start;
	flex-shrink: 0;
`;

const Image = styled.img`
	height: 80px;
	width: 80px;
	border-radius: 1000px;
`;

const InputContainer = styled.form`
	display: flex;
	padding-top: 8px;
	flex-direction: column;
	margin-top: 32px;
	gap: 8px;
	flex: 1 0 0;

	@media (max-width: 768px) {
		padding-top: 0px;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	align-items: center;
`;

const AddViewGrandpa: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const abueloData = location.state?.grandpaInfo || null;

	const [selectedHobbies, setSelectedHobbies] = useState<any[]>([]);
	const [selectedHobbiesValue, setSelectedHobbiesValue] = useState<any[]>([]);

	const [selectedLanguages, setSelectedLanguages] = useState<any[]>([]);
	const [selectedLanguagesValue, setSelectedLanguagesValue] = useState<any[]>(
		[]
	);

	const [selectedGender, setSelectedGender] = useState<string | undefined>(
		undefined
	);
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		undefined
	);

	const [loading, setLoading] = useState<boolean>(false);

	const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

	const [residence, setResidence] = useState<ResidenceScheme | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const [hasPassedOnImageCrop, setHasPassedOnImageCrop] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	

	const getResidence = (residenceId: string) => {
		Residence.getById(residenceId).then(
			({ data: residence }: { data: ResidenceScheme }) => {
				if (residence) {
					setResidence(residence);
				}
			}
		);
	};

	const getHobbiesByIdList = (idList: string[]) => {
		const hobbiesList = Constant.hobbies();
		return idList
			.map((id: string) => hobbiesList.find((hobby) => hobby.id === id))
			.filter(Boolean);
	};

	const getLanguagesByIdList = (idList: string[]) => {
		const languagesList = Constant.languages();
		return idList
			.map((id: string) =>
				languagesList.find((language) => language.id === id)
			)
			.filter(Boolean);
	};

	const getEditData = useCallback(() => {
		if (abueloData) {
			setFirstName(abueloData.name);
			setLastName(abueloData.surname);
			setSelectedGender(abueloData.gender || "");

			const hobbiesFound = getHobbiesByIdList(
				abueloData.data?.hobbies || []
			);
			setSelectedHobbies(hobbiesFound || []);

			const languagesFound = getLanguagesByIdList(
				abueloData.data?.languages || []
			);
			setSelectedLanguages(languagesFound || []);

			if (abueloData.birthday?.iso) {
				const dateObject = new Date(abueloData.birthday.iso);
				setSelectedDate(dateObject);
			}

			if (abueloData.image) {
				setImageUrl(abueloData.image.url);
			}
		}
	}, [abueloData]);

	const fetchData = useCallback(async () => {
		try {
			const { data: user } = await User.currentAsync();
			if (user) {
				getResidence(user.Residence?.objectId ?? "");
			} else {
				navigate("/login");
			}
		} catch (error: any) {
			console.error("Error fetching user:", error);
			if (error.code === 403) {
				navigate("/login");
			}
		}
	}, [navigate]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		getEditData();
	}, [getEditData]);

	const handleDateChange = (event: ChangeEvent<HTMLInputElement> | Date) => {
		let dateValue: string | Date;

		if (event instanceof Date) {
			dateValue = event;
		} else if ("target" in event && event.target.value) {
			dateValue = event.target.value;
		} else {
			console.warn(
				"Advertencia: No se pudo obtener el valor de la fecha."
			);
			return;
		}

		const momentDate = moment(dateValue, moment.ISO_8601, true).isValid()
			? moment(dateValue)
			: moment(dateValue, "YYYY-MM-DD");

		if (momentDate.isValid()) {
			setSelectedDate(momentDate.toDate());
		}
	};

	const handleTagChange = (value: { id: string }[]) => {
		setSelectedHobbies(value);

		const hobbyIds = value.map((hobby) => String(hobby.id));

		setSelectedHobbiesValue(hobbyIds);
	};

	const handleLanguagesChange = (value: { id: string }[]) => {
		setSelectedLanguages(value);

		const languageIds = value.map((language) => String(language.id));

		setSelectedLanguagesValue(languageIds);
	};

	const onImageCrop = (file: string) => {
		setHasPassedOnImageCrop(true);
		setImageUrl(file);
	};

	const handleGenderChange = (
		optionId: React.SetStateAction<string | undefined>
	) => {
		if (selectedGender !== optionId) {
			setSelectedGender(optionId);
		} else {
			setSelectedGender(undefined);
		}
	};

	const handleSave = () => {
		if (
			firstName &&
			lastName &&
			selectedGender &&
			selectedDate &&
			selectedHobbies.length > 0
		) {
			setLoading(true);
			const grandpaInfo = {
				name: firstName,
				surname: lastName,
				phone: residence?.phone ? residence.phone.toString() : "",
				gender: selectedGender,
				residenceId: residence?.objectId,
				address: residence?.address,
				shortAddress: residence?.shortAddress,
				country: residence?.country,
				latitude: residence?.location.latitude,
				longitude: residence?.location.longitude,
				languages: selectedLanguagesValue,
				hobbies: selectedHobbiesValue,
				birthday: selectedDate,
				...(hasPassedOnImageCrop && { image: imageUrl }),
			};

			if (abueloData) {
				User.set(abueloData.objectId, {
					name: firstName,
					surname: lastName,
					birthday: selectedDate,
					gender: selectedGender,
					data: {
						hobbies:
							selectedHobbiesValue.length > 0
								? selectedHobbiesValue
								: abueloData.data.hobbies,
						languages:
							selectedLanguagesValue.length > 0
								? selectedLanguagesValue
								: abueloData.data.languages,
					},
					...(hasPassedOnImageCrop && { image: imageUrl }),
				}).then((user) => {
					setLoading(false);
					navigate("/abuelos/registro/success", {
						state: {
							grandpa: user.data,
							message: `¡Has modificado a ${firstName}!`,
						},
					});
				});
			} else {
				Grandpa.register({
					...grandpaInfo,
					programs: ["letter"],
				}).then((user) => {
					setLoading(false);
					navigate("/abuelos/registro/success", {
						state: {
							grandpa: user.data,
							message: `¡Has registrado a ${firstName}!`,
						},
					});
				});
			}
		} else {
			setErrorMessage(
				"El nombre, apellidos, género, cumpleaños y hobbies son datos obligatorios."
			);
		}
	};

	return (
		<>
			<ParentContainer>
				<MainContainer>
					<Header>
						{abueloData ? (
							<X
								size={24}
								color={ColorV2.text.neutralHard}
								style={{
									cursor: "pointer",
									padding: 14,
								}}
								onClick={() => navigate(-1)}
							/>
						) : (
							<X
								size={24}
								color={ColorV2.text.neutralHard}
								style={{
									cursor: "pointer",
									padding: 14,
								}}
								onClick={() => navigate("/abuelos")}
							/>
						)}

						<CenteredText
							type="b1"
							weight="medium"
							style={{
								color: ColorV2.text.neutralHard,
							}}
						>
							{abueloData
								? "Editar persona mayor"
								: "Añadir persona mayor"}
						</CenteredText>
					</Header>
					<CenteredContainer>
						<DataContainer>
							<Text
								type="h5"
								weight="semibold"
								style={{
									marginBottom: "4px",
								}}
							>
								Foto y nombre
							</Text>
							<Text
								type="p2"
								style={{
									marginBottom: "24px",
								}}
							>
								Sácale su mejor sonrisa
							</Text>

							<ImageContainer>
								<Image
									src={
										imageUrl
											? imageUrl
											: isDesktop
											? require("../../../assets/images/figure.png")
											: require("../../../assets/images/profilePhoto.png")
									}
								/>
								<Input
									type="image"
									options={["library", "camera"]}
									style={{ marginLeft: 24, marginRight: 8 }}
									maxHeight={1000}
									maxWidth={1000}
									onChange={onImageCrop}
								/>
							</ImageContainer>

							<InputContainer style={{ gap: 8 }}>
								<Input
									type="text"
									placeholder="Nombre"
									design={isDesktop ? "primary" : "secondary"}
									value={firstName}
									onChange={(e) =>
										setFirstName(e.target.value)
									}
								/>
								<Input
									type="text"
									placeholder="Apellido"
									design={isDesktop ? "primary" : "secondary"}
									value={lastName}
									onChange={(e) =>
										setLastName(e.target.value)
									}
								/>
							</InputContainer>
						</DataContainer>
						<DataContainer>
							<Text
								type="h5"
								weight="semibold"
								style={{
									marginBottom: "4px",
								}}
							>
								Cumpleaños
							</Text>
							<Text
								type="p2"
								style={{
									marginBottom: "24px",
								}}
							>
								Selecciona su fecha de nacimiento
							</Text>
							{/*TODO The code is duplicated because if I use isDesktop ? "primary" : "secondary", showCalendar detects a mismatch and throws an error*/}{" "}
							{isDesktop ? (
								<Input
									type="date"
									placeholder="Fecha de nacimiento"
									showCalendar={true}
									design="primary"
									defaultValue={
										selectedDate
											? moment(selectedDate).format(
													"YYYY-MM-DD"
											  )
											: ""
									}
									onChange={handleDateChange}
								/>
							) : (
								<Input
									type="date"
									placeholder="Fecha de nacimiento"
									showCalendar={true}
									design="secondary"
									defaultValue={
										selectedDate
											? moment(selectedDate).format(
													"YYYY-MM-DD"
											  )
											: ""
									}
									onChange={handleDateChange}
								/>
							)}
						</DataContainer>

						<DataContainer>
							<Text
								type="h5"
								weight="semibold"
								style={{
									marginBottom: "4px",
								}}
							>
								Género
							</Text>
							<Text
								type="p2"
								style={{
									marginBottom: "24px",
								}}
							>
								Selecciona el género de la persona mayor
							</Text>
							<CellSelector
								style={{ gap: 16 }}
								type="single"
								design={isDesktop ? "design2" : "design1"}
								options={[
									{
										id: "female",
										title: "Mujer",
										icon: (
											<img
												src={require("../../../assets/images/woman-outlined.png")}
												alt="Female Icon"
												width="24px"
												height="24px"
											/>
										),
									},
									{
										id: "male",
										title: "Hombre",
										icon: (
											<img
												src={require("../../../assets/images/man-outlined.png")}
												alt="Male Icon"
												width="24px"
												height="24px"
											/>
										),
									},
									{
										id: "other",
										title: "Otro",
										icon: (
											<img
												src={require("../../../assets/images/shuffle-outlined.png")}
												alt="Other Icon"
												width="24px"
												height="24px"
											/>
										),
									},
								]}
								selectedOptions={
									selectedGender
										? [{ id: selectedGender, title: "" }]
										: []
								}
								onClick={(option) =>
									handleGenderChange(option.id)
								}
							/>
						</DataContainer>

						<DataContainer>
							<Text
								type="h5"
								weight="semibold"
								style={{
									marginBottom: "4px",
								}}
							>
								Aficiones
							</Text>
							<Text
								type="p2"
								style={{
									marginBottom: "24px",
								}}
							>
								Selecciona sus gustos más destacados
							</Text>
							<TagSelector
								options={Constant.hobbies().map((hobby) => ({
									...hobby,
									subtitle: "",
								}))}
								design={isDesktop ? "design2" : "design1"}
								type={"multiple"}
								optionsSelected={selectedHobbies}
								onChange={handleTagChange}
							/>
						</DataContainer>

						<DataContainer>
							<Text
								type="h5"
								weight="semibold"
								style={{
									marginBottom: "4px",
								}}
							>
								Idiomas
							</Text>
							<Text
								type="p2"
								style={{
									marginBottom: "24px",
								}}
							>
								¿Qué idiomas habla?
							</Text>
							<TagSelector
								style={{ marginBottom: 12 }}
								options={Constant.languages().map(
									(language) => ({
										...language,
										subtitle: "",
									})
								)}
								design={isDesktop ? "design2" : "design1"}
								type={"multiple"}
								optionsSelected={selectedLanguages}
								onChange={handleLanguagesChange}
							/>
						</DataContainer>
					</CenteredContainer>
					<Footer>
						<Feedback
							style={{ bottom: 88 }}
							type="error"
							isVisible={errorMessage ? true : false}
							text={errorMessage ? errorMessage : ""}
							onClose={() => setErrorMessage(undefined)}
						/>
						<StyledButtonFooter
							loading={loading}
							onClick={handleSave}
						>
							Guardar
						</StyledButtonFooter>
					</Footer>
				</MainContainer>
			</ParentContainer>
		</>
	);
};

export default AddViewGrandpa;
