import React from "react";
import styled from "styled-components";
import { Button, Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Check } from "lucide-react";
import { X } from "lucide-react";

const Header = styled.header`
	display: flex;
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	position: sticky;
	top: 0;
	z-index: 1000;
`;

const StyledButtonCheckProfile = styled(Button)`
	display: flex;
	width: 100%;
	padding: 15px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: var(--radius-Full, 1000px);
	transition: transform 0.3s ease-in-out;
	text-transform: none;

	&:active {
		transform: scale(0.95);
	}
`;
const CenteredContainer = styled.div`
	display: flex;
	padding: var(--Amount, 0px) 24px 24px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;
	text-align: center;
`;

const ParentContainer = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${ColorV2.surface.background};
	align-items: center;
	height: 100vh;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 0px;
	background-color: ${ColorV2.surface.background};
	height: 100vh;

	@media (min-width: 768px) {
		border-radius: 30px;
		background-color: ${ColorV2.surface.invert};
		height: 80vh;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	padding: 16px 24px;
	gap: 8px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
`;

const RegistrationViewSuccess: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state || {};
	const message = data.message || "¡Operación exitosa!";

	return (
		<>
			<ParentContainer>
				<MainContainer>
					<Header>
						<X
							size={24}
							color={ColorV2.text.neutralHard}
							style={{ cursor: "pointer", padding: 14 }}
							onClick={() => navigate("/abuelos")}
						/>
					</Header>
					<CenteredContainer>
						<Check
							height={32}
							width={32}
							color={ColorV2.text.primary}
						/>
						<Text
							type="h3"
							weight="semibold"
							style={{
								marginBottom: "12px",
								marginTop: "24px",
							}}
						>
							{message}
						</Text>
						<Text
							type="p2"
							weight="regular"
							style={{
								marginBottom: "32px",
							}}
						>
							Recuerda que pueden participar todos los mayores de
							tu residencia.
						</Text>
					</CenteredContainer>
					<ButtonContainer>
						<StyledButtonCheckProfile
							onClick={() => navigate("/abuelos/registro")}
						>
							Añadir otro mayor
						</StyledButtonCheckProfile>
					</ButtonContainer>
				</MainContainer>
			</ParentContainer>
		</>
	);
};

export default RegistrationViewSuccess;
