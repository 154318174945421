import { useState } from "react";
import { Regex, Auth, ErrorScheme } from "client-v2";
import styled from "styled-components";
import { Button, Text, Input, ColorV2 } from "@adoptaunabuelo/react-components";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../redux";
import { bindActionCreators } from "redux";

const MainContainer = styled.div`
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background: ${ColorV2.surface.background};
	@media (max-width: 768px) {
		align-items: flex-start;
	}
`;

const LoginContainer = styled.div`
	display: flex;
	width: 792px;
	padding: 24px 32px 32px 32px;
	gap: 16px;

	border-radius: var(--radius-L, 12px);
	background: ${ColorV2.surface.invert};

	@media (max-width: 768px) {
		flex-direction: column;
		border-radius: none;
		background: ${ColorV2.surface.background};
		width: 100%;
		gap: 0px;
		padding: 8px 24px;
	}
`;

const Column1 = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
`;

const Column2 = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
`;

const LoginForm = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	gap: 8px;
	flex: 1 0 0;
`;

const LogoImage = styled.img`
	width: 80px;
	height: 80px;
	align-self: flex-start;
	@media (max-width: 768px) {
		padding: 14px 0px 14px 0px;
		width: 44px;
		height: 44px;
	}
`;

const TagLineImage = styled.img`
	width: 128px;
	height: 40px;
	align-self: flex-start;

	@media (max-width: 768px) {
		display: none;
	}
`;

const StyledInput = styled(Input)`
	@media (max-width: 768px) {
		margin-top: 32px;
	}
`;

function Login() {
	const [loading, setLoading] = useState(false);
	const [credentials, setCredrentials] = useState({
		email: "",
		password: "",
	});
	const [credentialsErrors, setCredentialsErrors] = useState({
		email: "",
		password: "",
	});
	const [error, setError] = useState<string | undefined>(undefined);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setCurrentUser } = bindActionCreators(actionsCreators, dispatch);

	const onCredentialsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setError(undefined);
		if (name === "email") {
			let test = Regex.email(value);
			if (!test) {
				setCredentialsErrors({
					...credentialsErrors,
					email: "Email incorrecto",
				});
			} else setCredentialsErrors({ ...credentialsErrors, email: "" });
		}
		setCredrentials({ ...credentials, [name]: value });
	};

	const onLoginPress = async () => {
		if (!credentials.email || !credentials.password) {
			setError("No se pueden dejar campos vacíos");
			return;
		}

		if (
			!credentialsErrors.email &&
			credentials.email &&
			credentials.password
		) {
			setLoading(true);
			Auth.login(credentials.email, credentials.password)
				.then(({ data: user }) => {
					if (user.type === "residence" && user.active) {
						if(user.Residence) {
							setCurrentUser(user);
							navigate("/abuelos");
							setLoading(false);
						}else{
							setError("No tienes una residencia asociada");
							setLoading(false);
						}
					} else {
						setError("Parece que no tienes acceso");
						setLoading(false);
					}
				})
				.catch((error: ErrorScheme) => {
					setError(
						"No hay una ninguna cuenta asociada a este email."
					);
					setLoading(false);
				});
		}
	};

	return (
		<MainContainer>
			<LoginContainer>
				<Column1>
					<LogoImage
						src={require("../../assets/images/logo192.png")}
						alt="Logo"
					/>
					<TagLineImage
						src={require("../../assets/images/logo_line.png")}
						alt="img-tag-line"
					/>
				</Column1>
				<Column2>
					<Text type="h3" weight="semibold">
						¡Hola! Inicia sesión con tu cuenta de la residencia
					</Text>
					<LoginForm>
						<StyledInput
							id="email"
							design="secondary"
							type={"email"}
							name="email"
							placeholder="Email"
							error={credentialsErrors.email}
							onChange={onCredentialsChange}
						/>

						<Input
							name="password"
							design="secondary"
							placeholder="Contraseña"
							type="password"
							error={credentialsErrors.password}
							onChange={onCredentialsChange}
						/>
					</LoginForm>
					{error && <ErrorMessage message={error} />}
					<Button
						style={{ marginTop: "32px" }}
						loading={loading}
						onClick={onLoginPress}
					>
						Iniciar sesión
					</Button>
				</Column2>
			</LoginContainer>
		</MainContainer>
	);
}

export default Login;
