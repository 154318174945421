import styled from "styled-components";
import { Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { ChevronRight } from "lucide-react";

const StyledButton = styled.div`
	display: flex;
	padding: 12px 16px;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	align-self: stretch;
	border-radius: var(--radius-L, 12px);
	border: var(--Amount, 1px) solid ${ColorV2.border.neutralSoft};
	background: ${ColorV2.surface.invert};
`;

const TextContainer = styled.div`
	flex-grow: 1;
	text-align: left;
`;

const LettersBox: React.FC<Props> = (props) => {
	return (
		<StyledButton>
			<img
				src={require("../assets/images/Card.png")}
				alt="LogOut Modal"
				width="48px"
				height="48px"
			/>
			<TextContainer>
				
				<Text
					type="p"
					weight="medium"
					style={{
						color: ColorV2.text.neutralHard,
					}}
				>
					{props.title} cartas recibidas
				</Text>
				{/* 
				<Text
					type="c1"
					weight="regular"
					style={{
						color: ColorV2.text.neutralMedium,
					}}
				>
					{subtitle}
				</Text>
				*/}
			</TextContainer>
			<ChevronRight size="20" color={ColorV2.border.neutralMedium} />
		</StyledButton>
	);
};

interface Props {
	title: number;
	subtitle: string;
}

export default LettersBox;
