import { combineReducers } from "redux";
import { currentUserReducer } from "./data";
import { 
    letterTableFiltersReducer
} from './filter';

const reducers = combineReducers({
	currentUser: currentUserReducer,
	letterTableFilters: letterTableFiltersReducer,
});
export default reducers;

export type State = ReturnType<typeof reducers>;
