import { Action } from '../actions/index'
import { ActionType } from '../actions-types/index'

export const letterTableFiltersReducer = (filter = { descending: 'createdAt' }, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_LETTER_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

