import React, { useState } from "react";
import styled from "styled-components";
import { Text, ColorV2, Button } from "@adoptaunabuelo/react-components";
import es from "date-fns/locale/es";
import { Download, X, ArrowRight, Calendar } from "lucide-react";
import { format, parseISO } from "date-fns";
import html2pdf from "html2pdf.js";
import moment from "moment";
import letterHTML from "../../assets/letter/letter";
import stamp_empty from "../../assets/letter/stamp_empty.png";
import stamp_retro from "../../assets/letter/stamp_retro.png";

const ProfileContainer = styled.div`
	height: 600px;
	border: 1px solid #ccc;
	min-width: 450px;
	border-radius: 12px;
	background: #fff;
	border: 1px solid white;
	overflow-y: auto;
`;

const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
`;

const InfoContainer = styled.div`
	text-align: left;
	padding: 0px 16px 24px 16px;
`;

const ButtonContainer = styled.div`
	display: flex;
	cursor: pointer;
	gap: 8px;
`;

const DestinatarioContainer = styled.div`
	display: flex;
	text-align: left;
	align-items: center;
	gap: 8px;
	flex-direction: row;
`;

const ContentContainer = styled.div`
	display: flex;
	text-align: left;
	margin: 24px 0px;
`;

const FechaContainer = styled.div`
	display: flex;
	text-align: left;
	align-items: center;
	gap: 8px;
	flex-direction: row;
`;

const Profile: React.FC<Props> = (props) => {
	const [loading, setLoadingPDF] = useState(false);

	const formattedDate = format(
		parseISO(props.letter.createdAt as string),
		"dd MMM. yyyy",
		{ locale: es }
	);

	const toBase64 = async (url: string): Promise<string> => {
		const response = await fetch(url);
		const blob = await response.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	};

	const downloadLetter = async () => {
		if (props.letter) {
			setLoadingPDF(true);

			let logoBase64 = "";
			if (props.letter.Coorporate) {
				logoBase64 = await toBase64(props.letter.Coorporate.logo.url);
			}

			let html = await letterHTML();
			html = html.replace("{{content}}", props.letter.content);
			html = html.replace(
				"{{name}}",
				`${props.letter.User.name} ${props.letter.User.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(props.letter.User.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					props.letter.customization
						? props.letter.customization.font
						: "Poppins"
				}";`
			);
			if (props.letter.Coorporate) {
				html = html.replace("{{stamp_img}}", stamp_empty);
				html = html.replace(
					"{{corporate_logo}}",
					`<img src="${logoBase64}"/>`
				);
			} else {
				html = html.replace("{{stamp_img}}", stamp_retro);
				html = html.replace("{{corporate_logo}}", "");
			}
			if (props.letter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (props.letter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (props.letter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${props.letter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			//Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoadingPDF(false);
				});
		}
	};

	return (
		<ProfileContainer>
			<HeaderContainer>
				<Text type="p" weight="semibold">
					De{" "}
					{props.letter.User.name + " " + props.letter.User.surname}
				</Text>

				<ButtonContainer>
					<Button
						style={{
							padding: 10,
							backgroundColor: ColorV2.surface.primary,
							borderRadius: 1000,
						}}
						design={"call-to-action"}
						size="small"
						icon={<Download size={20} color="white" />}
						loading={loading}
						onClick={downloadLetter}
					></Button>
					<X
						size={24}
						onClick={props.onCloseLetter}
						style={{ padding: 8 }}
						color={ColorV2.text.neutralHard}
					/>
				</ButtonContainer>
			</HeaderContainer>
			<InfoContainer>
				<DestinatarioContainer>
					<ArrowRight size={16} color={ColorV2.surface.neutralHigh} />
					<Text
						type="p2"
						weight="regular"
						style={{
							color: ColorV2.text.neutralMedium,
						}}
					>
						a{" "}
						{props.letter.Grandpa?.name +
							" " +
							props.letter.Grandpa?.surname}
					</Text>
				</DestinatarioContainer>

				<FechaContainer>
					<Calendar size={16} color={ColorV2.surface.neutralHigh} />
					<Text
						type="p2"
						weight="regular"
						style={{
							color: ColorV2.text.neutralMedium,
						}}
					>
						Enviada el {formattedDate}
					</Text>
				</FechaContainer>

				<ContentContainer>
					<Text
						type="p2"
						weight="regular"
						style={{
							color: ColorV2.text.neutralHard,
						}}
					>
						{props.letter.content}
					</Text>
				</ContentContainer>
			</InfoContainer>
		</ProfileContainer>
	);
};

interface Props {
	letter: any; //Uso any aquí para evitar errores de tipado, si pongo LetterScheme
	//me da error al llamar al letter.createdAt porque me dice que tengo que usar .iso cuando no lo tiene
	onCloseLetter: () => void;
}

export default Profile;
