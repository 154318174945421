import { ColorV2, Text, Avatar, Menu } from "@adoptaunabuelo/react-components";
import {
	ComponentPropsWithoutRef,
	ReactElement,
	useState,
	useEffect,
} from "react";
import styled from "styled-components";
import Homepage from "../homepage/HomePage";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import { Auth, ErrorScheme } from "client-v2";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, AlignJustify } from "lucide-react";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
	background: ${ColorV2.surface.background};
	@media (max-width: 768px) {
		background: ${ColorV2.surface.invert};
	}
`;

const ResidenceNameContainer = styled.div`
	background-color: ${ColorV2.surface.invert};
	padding: 8px;
	padding-bottom: 4px;
`;

const EmailResidenceContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: var(--radius-L, 12px);
	padding: 8px;
	padding-top: 0px;
`;

const Divider = styled.hr`
	border: none;
	border-top: 1px solid ${ColorV2.text.neutralSoft};
	margin: 8px;
`;

const ModalLogOut = styled.div`
	cursor: pointer;
	padding: 5px 8px;
	border-radius: 1000px;
	&:hover {
		background-color: ${ColorV2.surface.neutralSoft};
	}
`;

const ClickableText = styled.div`
	cursor: pointer;
	margin: 0px;
`;

const Header = styled.header`
	display: flex;
	height: 56px;
	min-height: 56px;
	padding: 16px 32px 16px 24px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	background: ${ColorV2.surface.background};
	position: sticky;
	top: 0;
	z-index: 1000;

	@media (max-width: 768px) {
		padding: 14px 16px 14px 24px;
		background: ${ColorV2.surface.invert};
	}
`;

const ContainerChildren = styled.div`
	background-color: ${ColorV2.surface.background};
	@media (max-width: 768px) {
		background-color: ${ColorV2.surface.invert};
	}
`;

const TabNav = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const AvatarContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;

const TabItem = styled.div<{ $isSelected: boolean }>`
	position: relative;
	cursor: pointer;
	padding-bottom: 8px;
	transition: color 0.3s ease;
	margin-left: 16px;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 1000px;
		height: 3px;
		background-color: ${({ $isSelected }) =>
			$isSelected ? ColorV2.border.neutralHard : "transparent"};
		transition: background-color 0.3s ease;
	}
`;

const NavList = styled.ul`
	display: flex;
	list-style: none;
	padding: 0px;
	margin: 0;
`;

const ViewContainer = (props: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const currentUser = useSelector((state: State) => state.currentUser);

	const [isDesktop, setIsDesktop] = useState(false);
	const [selectedTab, setSelectedTab] = useState<"Mayores" | "Cartas">(
		"Mayores"
	);
	const ContactEmail = currentUser?.email;
		
	const navigate = useNavigate();

	const location = useLocation();

	const handleLogout = () => {
		Auth.logout().catch((error: ErrorScheme) => {
			console.error(error.message);
		});
		navigate("/login");
	};

	useEffect(() => {
		if (location.pathname === "/abuelos") {
			setSelectedTab("Mayores");
		} else if (location.pathname === "/cartas") {
			setSelectedTab("Cartas");
		}
	}, [location.pathname]);

	const handleTabClick = (tab: "Mayores" | "Cartas") => {
		if (tab === "Mayores" && location.pathname !== "/abuelos") {
			navigate("/abuelos");
		} else if (tab === "Cartas" && location.pathname !== "/cartas") {
			navigate("/cartas");
		}
	};


	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(window.innerWidth > 768);
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			{!isDesktop ? (
				<Homepage
					isModalOpen={isModalOpen}
					closeModal={() => setIsModalOpen(false)}
					residenceName={currentUser?.Residence?.name || ""}
				/>
			) : null}

			<Container {...props}>
				<Header>
					{props.$onBackClick ? (
						<NavList>
							<ArrowLeft
								size={24}
								onClick={props.$onBackClick}
								color={ColorV2.text.neutralHard}
								style={{ cursor: "pointer" }}
							/>
						</NavList>
					) : (
						<>
							{isDesktop ? (
								<>
									<TabNav>
										<img
											src={require("../../assets/images/logo192.png")}
											width="44px"
											height="44px"
											alt="Logo Adopta"
										/>

										<TabItem
											$isSelected={
												selectedTab === "Mayores"
											}
											onClick={() => {
												handleTabClick("Mayores");
											}}
										>
											<Text
												type="b2"
												weight="medium"
												style={{
													cursor: "pointer",
													color:
														selectedTab ===
														"Mayores"
															? ColorV2.text
																	.neutralHard
															: ColorV2.text
																	.neutralMedium,
												}}
											>
												Mayores
											</Text>
										</TabItem>
										<TabItem
											$isSelected={
												selectedTab === "Cartas"
											}
											onClick={() => {
												handleTabClick("Cartas");
											}}
										>
											<Text
												type="b2"
												weight="medium"
												style={{
													cursor: "pointer",
													color:
														selectedTab === "Cartas"
															? ColorV2.text
																	.neutralHard
															: ColorV2.text
																	.neutralMedium,
												}}
											>
												Cartas
											</Text>
										</TabItem>
									</TabNav>
									<AvatarContainer>
										<Menu
											id={"MyMenu"}
											position={"bottom-left"}
											menuStyle={{
												padding: "8px",
											}}
											icon={
												<Avatar
													name={
														currentUser?.Residence?.name || ""
													}
													icon=""
													style={{
														cursor: "pointer",
														color: ColorV2.text
															.invert,
														backgroundColor:
															ColorV2.surface
																.neutralMedium,
														height: 44,
														width: 44,
														marginTop: "0px",
														fontSize: 15,
													}}
												/>
											}
										>
											<ResidenceNameContainer>
												<Text
													type="h6"
													weight="semibold"
													style={{
														color: ColorV2.text
															.neutralHard,
													}}
												>
													{currentUser?.Residence?.name || ""}
												</Text>
											</ResidenceNameContainer>

											<EmailResidenceContainer>
												<Text
													type="p2"
													weight="regular"
													style={{
														color: ColorV2.text
															.neutralHard,
													}}
												>
													{ContactEmail}
												</Text>
											</EmailResidenceContainer>
											<Divider />
											<ClickableText>
												<ModalLogOut>
													<Text
														onClick={handleLogout}
														type="p2"
														weight="regular"
														style={{
															color: ColorV2.text
																.neutralHard,
															borderRadius:
																"1000px",
														}}
													>
														Cerrar sesión
													</Text>
												</ModalLogOut>
											</ClickableText>
										</Menu>
									</AvatarContainer>
								</>
							) : (
								<img
									src={require("../../assets/images/logo192.png")}
									width="44px"
									height="44px"
									alt="Logo Adopta"
								/>
							)}
						</>
					)}

					{props.$NabBarButton && (
						<NavList>{props.$NabBarButton}</NavList>
					)}

					{props.$showMenu && (
						<NavList>
							{!isDesktop && (
								<AlignJustify
									size={24}
									style={{
										padding: 14,
										color: ColorV2.text.neutralHard,
									}}
									onClick={() => setIsModalOpen(true)}
								/>
							)}
						</NavList>
					)}
				</Header>
				<ContainerChildren>{props.children}</ContainerChildren>
			</Container>
		</>
	);
};

export default ViewContainer;

export interface Props extends ComponentPropsWithoutRef<"div"> {
	$showMenu?: boolean;
	$onBackClick?: () => void;
	$NabBarButton?: ReactElement;
}
