import { Route, Routes } from "react-router-dom";

import LettersView from "../views/Letters/Letters";

const LetterLayout = () => {
	return (
		<Routes>
			<Route path="/" element={<LettersView />} />
		</Routes>
	);
};

export default LetterLayout;
export interface Props {}
