import { ActionType } from "../actions-types/index";
import { Dispatch } from "redux";
import { Action } from "../actions";
import { UserScheme } from "client-v2";

export const setCurrentUser = (user: UserScheme | null) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_CURRENT_USER,
			data: user,
		});
	};
};

export const setLetterTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_LETTER_TABLE_FILTERS,
			data: filters,
		});
	};
};
