import React from "react";
import styled from "styled-components";
import { Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { useNavigate } from "react-router-dom";
import { X, Frown } from "lucide-react";

const Header = styled.header`
	display: flex;
	padding: 8px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	position: sticky;
	top: 0;
	z-index: 1000;
	@media (max-width: 768px) {
		background-color: ${ColorV2.surface.invert};
	}
`;

const ParentContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${ColorV2.surface.background};
	height: 100vh;
`;

const CenteredContainer = styled.div`
	display: flex;
	padding: var(--Amount, 0px) 24px 24px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	flex: 1 0 0;
	align-self: stretch;
	text-align: center;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 30px;
	background-color: ${ColorV2.surface.invert};
	height: 100vh;

	@media (min-width: 768px) {
		background-color: ${ColorV2.surface.invert};
		height: 80vh;
	}
`;

const UnsubscribeViewScreen: React.FC = () => {
	const navigate = useNavigate();

	return (
		<>
			<ParentContainer>
				<MainContainer>
					<Header>
						<X
							size={24}
							color={ColorV2.text.neutralHard}
							style={{ cursor: "pointer", padding: 14 }}
							onClick={() => navigate("/abuelos")}
						/>
					</Header>
					<CenteredContainer>
						<Frown
							size={32}
							style={{
								padding: 16,
								borderRadius: "1000px",
								backgroundColor: ColorV2.surface.background,
								color: ColorV2.border.neutralHigh,
							}}
						/>
						<Text
							type="h3"
							weight="semibold"
							style={{
								color: ColorV2.text.neutralHard,
								marginBottom: "12px",
								marginTop: "24px",
							}}
						>
							Usuario dado de baja
						</Text>
						<Text
							type="p2"
							weight="regular"
							style={{
								color: ColorV2.text.neutralHard,
								marginBottom: "32px",
							}}
						>
							Sentimos que hayas tenido que tomar esta decisión,
							no nos gustan las despedidas.
						</Text>

						<Text
							type="b1"
							weight="semibold"
							onClick={() => navigate("/abuelos")}
							style={{
								color: ColorV2.text.invert,
								backgroundColor: ColorV2.surface.primary,
								display: "flex",
								width: "160px",
								cursor: "pointer",
								padding: "15px 20px",
								borderRadius: "1000px",
								gap: "6px",
								textAlign: "center",
								justifyContent: "center",
							}}
						>
							Aceptar
						</Text>
					</CenteredContainer>
				</MainContainer>
			</ParentContainer>
		</>
	);
};

export default UnsubscribeViewScreen;
