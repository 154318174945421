import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import ListLettersApp from "../../components/lists/ListLettersApp";
import { LetterScheme, Residence } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import ViewContainer from "../../components/container/ViewContainer";
import LetterDesktop from "../../components/letterDesktop/LetterDesktop";
import { bindActionCreators } from "redux";

const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: auto;
`;

const TableContainer = styled.div<{ $letter: LetterScheme | null }>`
	margin: 0px 24px;

	@media (min-width: 768px) {
		margin: ${({ $letter }) =>
			$letter ? "0px 16px 0px 80px" : "0px 80px"};
	}
`;

const LetterContainer = styled.div`
	margin: 0px 80px 0px 0px;
`;

const CardsView: React.FC = () => {
	const location = useLocation();
	const currentUser = useSelector((state: State) => state.currentUser);
	const [loading, setLoading] = useState(false);
	const [letters, setLetters] = useState<LetterScheme[]>([]);
	const [letter, setLetter] = useState<LetterScheme | null>(null);
	const [count, setCount] = useState(0);
	const dispatch = useDispatch();
	const [isDesktop, setIsDesktop] = useState(false);
	const abueloData = location.state?.grandpaInfo || null;

	const { setLetterTableFilters } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const letterTableFilters = useSelector(
		(state: State) => state.letterTableFilters
	);

	const onFiltersChange = (updatedFilters: any) => {

		const { createdAt = {}, ...restFilters } = {
			...letterTableFilters,
			...updatedFilters,
		};

		const shouldIncludeCreatedAt = createdAt?.min || createdAt?.max;

		const newFilters = shouldIncludeCreatedAt
			? {
					...restFilters,
					createdAt: {
						min: createdAt?.min,
						max: createdAt?.max,
					},
			  }
			: restFilters;

		setLetterTableFilters(newFilters);
		getLetterList(newFilters);
	};

	useEffect(() => {
		const updateMedia = () => {
			setIsDesktop(window.innerWidth >= 768);
		};

		updateMedia();
		window.addEventListener("resize", updateMedia);

		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	const getLetterList = async (filter: any) => {
		setLoading(true);

		if (currentUser && currentUser.Residence) {
			try {
				const result = await Residence.getByIdLetters(
					currentUser.Residence.objectId,
					filter.grandpaId || "",
					{
						...filter,
						include: ["User", "Grandpa", "Coorporate"],
						limit: 50,
						descending: "createdAt",
					}
				);
				setCount(result.pagination.count);
				setLetters(result.data);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching letters:", error);
			} finally {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	};

	const handleSelectLetter = (letter: LetterScheme) => {
		if (isDesktop) {
			setLetter(letter);
		}
	};

	const handleCloseLetter = () => {
		setLetter(null);
	};

	return (
		<>
			<ViewContainer $showMenu={true}>
				<ContentContainer>
					<>
						<TableContainer $letter={letter}>
							<ListLettersApp
								loading={loading}
								count={count}
								letters={letters}
								onSelectLetter={handleSelectLetter}
								page={letterTableFilters.page}
								abueloData={abueloData}
								onFiltersChange={onFiltersChange}
							/>
						</TableContainer>

						{isDesktop && letter ? (
							<LetterContainer>
								<LetterDesktop
									letter={letter}
									onCloseLetter={handleCloseLetter}
								/>
							</LetterContainer>
						) : null}
					</>
				</ContentContainer>
			</ViewContainer>
		</>
	);
};

export default CardsView;
