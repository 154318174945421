export const shutdownMotivations = () => {
    return {
        motivations: [
            { id: "dead", label: "Fallecimiento" },
            { id: "out-residence", label: "Baja de la residencia" },
            { id: "lost_capabilities", label: "Pérdida de capacidades" },
            { id: "adopta", label: "No ha tenido una buena experiencia" },
            { id: "no-participation", label: "No interés en participar" },
        ]
    }
}