import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
	Text,
	Modal,
	ColorV2,
	TagSelector,
	Checkbox,
	Feedback,
	Menu,
} from "@adoptaunabuelo/react-components";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { UserScheme, Constant, Grandpa, Letter } from "client-v2";
import { differenceInYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { State } from "../../redux";
import { useSelector } from "react-redux";
import { shutdownMotivations } from "../../data/shutdownMotivations";
import LettersBox from "../../components/LettersBox";
import { Ellipsis, X } from "lucide-react";

const ProfileContainer = styled.div`
	height: 600px;
	border: 1px solid #ccc;
	min-width: 450px;
	border-radius: 12px;
	background: #fff;
	border: 1px solid white;
	overflow-y: auto;
`;

const NameContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 12px 8px 12px 16px;
`;

const InfoContainer = styled.div`
	text-align: left;
	padding: 0px 16px 24px 16px;
`;

const DescriptionContainer = styled.div`
	margin-left: 16px;
	margin-right: 16px;
	margin-bottom: 24px;
	text-align: left;
`;

const LabelContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const StyledImg = styled.img`
	margin-right: 4px;
	height: 16px;
	width: 16px;
`;

const AdoptionInfo = styled.div`
	display: flex;
	align-items: center;
	padding: 2px 0;
	margin-top: 4px;
`;

const ButtonContainer = styled.div`
	display: flex;
	cursor: pointer;
	gap: 8px;
`;

const StyledDivEdit = styled.div`
	width: auto;
	display: flex;
	margin-top: 8px;
	padding: 15px 16px;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 12px;
	&:hover {
		background-color: ${ColorV2.text.neutralSoft};
	}
`;

const StyledDivDown = styled.div`
	width: auto;
	display: flex;
	margin-bottom: 16px;
	padding: 15px 16px;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	border-radius: 12px;

	transition: background-color 0.3s ease;

	&:hover {
		background-color: ${ColorV2.text.neutralSoft};
	}
`;

const StyledButton2 = styled.button`
	display: flex;
	padding: 15px 20px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 100%;
	border-radius: var(--radius-Full, 1000px);
	transition: transform 0.3s ease-in-out;
	text-transform: none;
	margin-top: 8px;
	border: none;
	background: none;
	cursor: pointer;

	&:active {
		transform: scale(0.95);
	}
`;

const StyledButtonUnsubscribe = styled(StyledButton2)`
	background: ${ColorV2.surface.red};
	color: #fff;
	align-self: stretch;

	&:hover {
		background-color: #c02626;
	}
`;

const CheckBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	padding: 12px 16px;
	background-color: ${ColorV2.surface.invert};
	margin-bottom: 24px;
`;

const StyledModalContent = styled.div`
	padding-top: 12px;
	margin-bottom: 16px;
	border-radius: 30px 30px 0 0;
	display: flex;
	flex-direction: column;
`;

const calculateTimeSince = (date: Date) => {
	const today = new Date();
	let years = today.getFullYear() - date.getFullYear();
	let months = today.getMonth() - date.getMonth();
	let days = today.getDate() - date.getDate();

	if (months < 0) {
		years--;
		months += 12;
	}

	if (days < 0) {
		months--;
		const lastMonth = new Date(
			today.getFullYear(),
			today.getMonth(),
			0
		).getDate();
		days += lastMonth;
	}

	return { years, months, days };
};

const Profile: React.FC<Props> = (props) => {
	const birthDate = new Date(props.grandpa.birthday?.iso || "");
	const currentYear = new Date();
	const age = differenceInYears(currentYear, birthDate);
	const formattedBirthDate = format(birthDate, "dd MMM yyyy", { locale: es });
	const fullName = `${props.grandpa.name} ${props.grandpa.surname}`;
	const adoptionDate = new Date(props.grandpa.createdAt);
	const { years, months, days } = calculateTimeSince(adoptionDate);
	const navigate = useNavigate();
	const currentUser = useSelector((state: State) => state.currentUser);
	const [isModalUnsubscribeOpen, setisModalUnsubscribeOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const [checkboxMotivation, setCheckboxMotivation] = useState<{
		id: string;
		label: string;
	} | null>(null);

	const [cardCount, setCardCount] = useState(0);

	const fetchCardCount = useCallback(() => {
		if (props.grandpa && props.grandpa.objectId) {
			Letter.getCountForGrandpa(props.grandpa.objectId)
				.then((result) => {
					setCardCount(result.data);
				})
				.catch((error) => {
					console.error(
						"Error al obtener el conteo de cartas: ",
						error
					);
				});
		}
	}, [props.grandpa]);

	useEffect(() => {
		fetchCardCount();
	}, [fetchCardCount]);

	const motivationsList = shutdownMotivations().motivations;

	const onOptionChange = (selectedOptionId: string) => {
		const selectedMotivation = motivationsList.find(
			(motivation) => motivation.id === selectedOptionId
		);
		if (selectedMotivation) {
			setCheckboxMotivation(selectedMotivation);
		} else {
			console.error("Motivation not found for id:", selectedOptionId);
		}
	};

	const handleNavigateToLetters = () => {
		if (cardCount === 0) {
			setErrorMessage("No hay cartas para mostrar");
		} else {
			const grandpaInfo = props.grandpa;
			navigate("/cartas", { state: { grandpaInfo } });
		}
	};

	const handleUnsubscribe = () => {
		if (currentUser) {
			if (checkboxMotivation) {
				Grandpa.remove(props.grandpa.objectId, {
					motivation: checkboxMotivation,
					adminId: currentUser.objectId,
				});
				navigate("/abuelos/unsubscribe-screen");
			} else {
				setErrorMessage("Debes seleccionar un motivo");
			}
		} else {
			console.error("currentUser es null");
		}
	};

	const handleUnsubscribeClose = () => {
		setisModalUnsubscribeOpen(false);
	};

	const handleEditProfile = () => {
		const grandpaInfo = props.grandpa;
		navigate("/abuelos/registro", { state: { grandpaInfo } });
	};

	const foundHobbies =
	props.grandpa.data?.hobbies && props.grandpa.data.hobbies.length > 0
			? Constant.hobbies().filter((hobby) =>
			props.grandpa.data?.hobbies?.includes(hobby.id)
			  )
			: [];

	const foundLanguages =
	props.grandpa.data?.languages && props.grandpa.data.languages.length > 0
			? Constant.languages().filter((language) =>
			props.grandpa.data?.languages?.includes(language.id)
			  )
			: [];

	const timeSinceAdoption = () => {
		if (years > 0) {
			return `Registrado hace ${years} ${years === 1 ? "año" : "años"}`;
		} else if (months > 0) {
			return `Registrado hace ${months} ${
				months === 1 ? "mes" : "meses"
			}`;
		} else if (days > 0) {
			return `Registrado hace ${days} ${days === 1 ? "día" : "días"}`;
		} else {
			return "Registrado hace menos de un día";
		}
	};

	return (
		<ProfileContainer>
			<HeaderContainer>
				<img
					height={80}
					width={80}
					alt="figure"
					style={{ borderRadius: "50%" }}
					src={
						props.grandpa.image?.url
							? props.grandpa.image.url
							: require("../../assets/images/figure.png")
					}
				/>
				<ButtonContainer>
					<div style={{}}></div>
					<Menu
						id={"MyMenu2"}
						position={"bottom-left"}
						icon={
							<Ellipsis
								size={24}
								color={ColorV2.text.neutralHard}
							/>
						}
					>
						<>
							<StyledDivEdit onClick={handleEditProfile}>
								<div style={{ cursor: "pointer" }}>
									<Text
										type="p"
										weight="medium"
										style={{
											color: ColorV2.text.neutralHard,
										}}
									>
										Editar Perfil
									</Text>
								</div>
							</StyledDivEdit>
							<StyledDivDown>
								<div style={{ cursor: "pointer" }}>
									<Text
										type="p"
										weight="medium"
										style={{
											color: ColorV2.text.red,
										}}
										onClick={() => {
											setisModalUnsubscribeOpen(true);
										}}
									>
										Dar de baja
									</Text>
								</div>
							</StyledDivDown>
						</>
					</Menu>
					<X
						size={24}
						onClick={props.onCloseProfile}
						color={ColorV2.text.neutralHard}
						style={{ marginTop: 12, marginRight: 8 }}
					/>
				</ButtonContainer>
			</HeaderContainer>
			<InfoContainer>
				<NameContainer>
					<Text
						type="h5"
						weight="semibold"
						style={{
							color: ColorV2.text.neutralHard,
						}}
					>
						{fullName}
					</Text>
				</NameContainer>

				<Text
					type="p"
					weight="regular"
					style={{
						color: ColorV2.text.neutralHard,
						fontFeatureSettings: "'liga' off, 'clig' off",
						fontFamily: "Poppins",
						fontSize: "15px",
						fontStyle: "normal",
						fontWeight: 400,
						lineHeight: "24px",
					}}
				>
					{`${age} años - ${formattedBirthDate}`}
				</Text>
				<AdoptionInfo>
					<StyledImg
						src={require("../../assets/images/radio.check.png")}
					/>
					<Text
						type="c1"
						weight="regular"
						style={{
							color: ColorV2.text.neutralMedium,
						}}
					>
						{timeSinceAdoption()}
					</Text>
				</AdoptionInfo>
			</InfoContainer>

			<DescriptionContainer
				onClick={handleNavigateToLetters}
				style={{ cursor: "pointer" }}
			>
				
				<LettersBox title={cardCount} subtitle="Desde hace dos años" />

				{errorMessage === "No hay cartas para mostrar" && (
					<Feedback
						type="error"
						isVisible={errorMessage ? true : false}
						text={errorMessage ? errorMessage : ""}
						onClose={() => setErrorMessage(null)}
					/>
				)}
			</DescriptionContainer>
			<DescriptionContainer>
				<Text type="h6" weight="medium">
					Aficiones
				</Text>
				<LabelContainer>
					{foundHobbies.length > 0 ? (
						<TagSelector
							onlyVisual={true}
							design="design2"
							options={foundHobbies.map((hobby) => ({
								...hobby,
								subtitle: "",
							}))}
						/>
					) : (
						<Text
							type="p2"
							style={{
								color: ColorV2.text.neutralMedium,
							}}
						>
							No se encontraron hobbies.
						</Text>
					)}
				</LabelContainer>
			</DescriptionContainer>
			<DescriptionContainer>
				<Text type="h6" weight="medium">
					Idiomas
				</Text>

				<LabelContainer>
					{foundLanguages.length > 0 ? (
						<TagSelector
							onlyVisual={true}
							design="design2"
							options={foundLanguages.map((hobby) => ({
								...hobby,
								subtitle: "",
							}))}
						/>
					) : (
						<Text
							type="p2"
							style={{
								color: ColorV2.text.neutralMedium,
							}}
						>
							No se encontraron idiomas.
						</Text>
					)}
				</LabelContainer>
			</DescriptionContainer>

			<Modal
				type="default"
				isVisible={isModalUnsubscribeOpen}
				hideHeader={true}
				style={{ backgroundColor: ColorV2.surface.background }}
				onClose={handleUnsubscribeClose}
				shouldCloseOnOverlayClick={true}
				hideClose={true}
			>
				<StyledModalContent onClick={(e) => e.stopPropagation()}>
					<Text
						type="h5"
						weight="semibold"
						style={{
							marginBottom: "12px",
						}}
					>
						¿Por qué quieres dar de baja al abuelo?
					</Text>

					<Text
						type="p2"
						weight="regular"
						style={{
							marginBottom: "24px",
						}}
					>
						Queremos saber en qué podemos mejorar.
					</Text>

					<CheckBoxContainer>
						<Checkbox
							style={{ width: "100%", borderRadius: "1000px" }}
							options={motivationsList}
							selectedOptions={
								checkboxMotivation
									? [{ id: checkboxMotivation.id }]
									: []
							}
							shape="circle"
							type={"single"}
							position={"right"}
							onChange={(selectedOptions: { id: string }[]) => {
								if (selectedOptions.length > 0) {
									onOptionChange(selectedOptions[0].id);
								}
							}}
						/>
					</CheckBoxContainer>

					<StyledButtonUnsubscribe onClick={handleUnsubscribe}>
						<Text
							type="b1"
							weight="semibold"
							style={{ color: "white" }}
						>
							Dar de baja
						</Text>
					</StyledButtonUnsubscribe>
					{errorMessage === "Debes seleccionar un motivo" && (
						<Feedback
							style={{ bottom: 60 }}
							type="error"
							isVisible={errorMessage ? true : false}
							text={errorMessage ? errorMessage : ""}
							onClose={() => setErrorMessage(null)}
						/>
					)}
				</StyledModalContent>
			</Modal>
		</ProfileContainer>
	);
};

interface Props {
	grandpa: UserScheme;
	onCloseProfile: () => void;
}

export default Profile;
