import { Action } from "../actions";
import { ActionType } from "../actions-types";
import { UserScheme } from "client-v2";

export const currentUserReducer = (user: UserScheme | null = null, action: Action): UserScheme | null => {
	switch (action.type) {
		case ActionType.SET_CURRENT_USER:
			return action.data;
		default:
			return user;
	}
};
