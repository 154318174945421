import stamp_aua from './stamp_aua.png';

const letterHTML = (): string => {
	return `
<html>
	<head>
		<meta charset="utf8" />
		<title>Una carta para un abuelo</title>
		<link rel="preconnect" href="https://fonts.googleapis.com" />
		<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
		<link
			href="https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=STIX+Two+Text:ital,wght@0,400..700;1,400..700&family=Schoolbell&display=swap"
			rel="stylesheet"
		/>
		<style>
			html,
			body {
				height: 100%;
				margin: 0;
				padding: 0;
				font-family: "Poppins";
				font-weight: 400;
				font-size: 13px;
				background: white;
				-webkit-print-color-adjust: exact;
				box-sizing: border-box;
			}
			body {
				padding: 40px 48px;
				width: 210mm;
				height: 297mm;
				transform: scale(0.7);
				transform-origin: top left;
			}
			h1 {
				font-size: 28px;
				color: rgba(0, 29, 61, 0.92);
				margin: 0px;
				font-style: normal;
				font-weight: 600;
				line-height: 36px;
			}
			h3 {
				font-size: 20px;
				color: rgba(0, 29, 61, 0.92);
				margin: 0px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px;
			}
			h6 {
				font-size: 18px;
				color: rgba(0, 29, 61, 0.92);
				margin: 0px;
				font-style: normal;
				font-weight: 600;
				line-height: 26px;
			}
			p {
				font-size: 15px;
				color: rgba(0, 29, 61, 0.92);
				margin: 0px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
			}
			.page {
				position: relative;
				display: block;
			}
			.content {
				position: relative;
			}
			.hide {
				opacity: 0;
			}
			.show {
				opacity: 1;
			}
			.header {
				margin-bottom: 48px;
			}
			.header .line {
				border-bottom: 1px solid rgba(0, 29, 61, 0.1);
				margin-top: 24px;
				width: 80px;
			}
			.stamp-aua {
				position: absolute;
				top: 32px;
				right: 85px;
				width: 98px;
				transform: rotate(10deg);
			}
			.stamp-retro {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 132px;
				height: 110px;
				background-size: cover;
			}
			.stamp-retro img {
				width: 90px;
				height: 54px;
				position: absolute;
				top: 27px;
				right: 21px;
				object-fit: contain;
			}
			.aua__page-break {
				margin-top: 32px;
			}
			.letter-image {
				width: 100%;
				text-align: center;
			}
			.letter-image img {
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		</style>
	</head>
	<body>
		<div class="page">
			<div class="header show">
				<h6>{{name}}</h6>
				<p>{{years}}</p>
				<div class="line"></div>
			</div>
			<div
				class="stamp-retro show"
				style="background-image: url('{{stamp_img}}')"
			>
				{{corporate_logo}}
			</div>
			<img class="stamp-aua show" src=${stamp_aua} />
			<div class="content">{{content}}</div>
			{{user-image}}
		</div>
	</body>
</html>
	`;
};

export default letterHTML;
