import { Route, Routes } from "react-router-dom";

import UnsubscribeViewScreen from "../views/Grandpas/ProfileGrandpa/UnsubscribeScreen";
import RegistrationViewSuccess from "../views/Grandpas/AddGrandpa/RegistrationSuccess";
import AddViewGrandpa from "../views/Grandpas/AddGrandpa/AddGrandpa";
import GrandpaView from "../views/Grandpas/Grandpas";
import ProfileGrandpa from "../views/Grandpas/ProfileGrandpa/ProfileGrandpa";

const GrandpaLayout = () => {
	return (
		<Routes>
			<Route path="/" element={<GrandpaView />} />
			<Route path="/registro" element={<AddViewGrandpa />} />
			<Route
				path="/registro/success"
				element={<RegistrationViewSuccess />}
			/>

			<Route path="/:objectId" element={<ProfileGrandpa />} />
			<Route
				path="/unsubscribe-screen"
				element={<UnsubscribeViewScreen />}
			/>
		</Routes>
	);
};

export default GrandpaLayout;
export interface Props {}
