import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "../views/Login/Login";
import { AUA, ErrorScheme, User } from "client-v2";
import GrandpaLayout from "./GrandpaLayout";
import LetterLayout from "./LetterLayout";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../redux";
import { bindActionCreators } from "redux";

const documentProperties = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
	doc.style.setProperty("--doc-width", `${window.innerWidth}px`);
	doc.style.setProperty("--nav-bar-height", `72px`);
	doc.style.setProperty("--nav-bar-height-mobile", `62px`);
};

function App() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setCurrentUser } = bindActionCreators(actionsCreators, dispatch);

	useEffect(() => {
		initServer();
		window.addEventListener("resize", documentProperties);
		documentProperties();
		return () => {
			window.removeEventListener("resize", documentProperties);
		};
	}, []);

	const initServer = async () => {
		const env: any = process.env.REACT_APP_ENV
			? process.env.REACT_APP_ENV
			: process.env.NODE_ENV;
		await AUA.initServer(
			env,
			process.env.REACT_APP_LOCALHOST ? true : false,
			undefined,
			true
		);
		User.currentAsync({
			include: ["Residence"],
		})
			.then(({ data: user }) => {
				if (user && user.type === "residence") {
					setCurrentUser(user);
					navigate("/abuelos");
				} else {
					navigate("/login");
				}
			})
			.catch((error: ErrorScheme) => {
				if (error.code === 403) {
					navigate("/login");
				}
			});
	};

	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/abuelos/*" element={<GrandpaLayout />} />
			<Route path="/cartas/*" element={<LetterLayout />} />
		</Routes>
	);
}

export default App;
