import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { LetterScheme } from "client-v2";

const ScrollableRowContainer = styled.div<{ isOptionSelected: boolean }>`
	display: flex;
	align-items: center;
	max-width: 330px;
	min-width: ${({ isOptionSelected }) =>
		isOptionSelected ? "none" : "330px"};
	overflow: hidden;
	white-space: nowrap;
`;

const LetterNamesAppContainer = styled.div<{ scrollDuration: number }>`
	display: inline-flex;
	align-items: center;
	white-space: nowrap;

	${({ scrollDuration }) =>
		scrollDuration > 0 &&
		`
    animation: scroll-horizontal ${scrollDuration + 5}s linear infinite;
  `}

	@keyframes scroll-horizontal {
		0% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(calc(-100% + 320px));
		}
		100% {
			transform: translateX(0);
		}
	}
`;

const GrandpaNameContainer = styled(Text)`
	white-space: nowrap;
	margin-right: 8px;
	flex: 1;
`;

const LetterNamesAnimation: React.FC<Props> = (props) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const [scrollDuration, setScrollDuration] = useState(0);

	useEffect(() => {
		if (scrollRef.current) {
			const contentWidth = scrollRef.current.scrollWidth;
			const containerWidth = scrollRef.current.clientWidth;
			const overflowAmount = contentWidth - containerWidth;

			const duration = overflowAmount > 0 ? overflowAmount / 50 : 0;

			setScrollDuration(duration);
		}
	}, [props.letter, props.isOptionSelected]);

	return (
		<ScrollableRowContainer
			ref={scrollRef}
			isOptionSelected={props.isOptionSelected}
		>
			<LetterNamesAppContainer scrollDuration={scrollDuration}>
				{props.isOptionSelected && (
					<Text
						type="p"
						weight="regular"
						style={{
							color: ColorV2.text.neutralMedium,
							marginRight: "4px",
						}}
					>
						De
					</Text>
				)}

				{!props.isOptionSelected && (
					<Text
						type="p"
						weight="regular"
						style={{
							color: ColorV2.text.neutralMedium,
							marginRight: "4px",
						}}
					>
						De
					</Text>
				)}

				<Text
					type="p"
					weight="regular"
					style={{
						color: ColorV2.text.neutralHard,
						marginRight: "4px",
						flexShrink: 0,
					}}
				>
					{props.letter.User.name} {props.letter.User.surname}
				</Text>

				{!props.isOptionSelected && (
					<>
						<Text
							type="p"
							weight="regular"
							style={{
								color: ColorV2.text.neutralMedium,
								marginRight: "4px",
							}}
						>
							a
						</Text>

						<GrandpaNameContainer
							type="p"
							weight="regular"
							style={{
								color: ColorV2.text.neutralHard,
							}}
						>
							{props.letter.Grandpa?.name}{" "}
							{props.letter.Grandpa?.surname}
						</GrandpaNameContainer>
					</>
				)}
			</LetterNamesAppContainer>
		</ScrollableRowContainer>
	);
};

interface Props {
	letter: LetterScheme;
	isOptionSelected: boolean;
}

export default LetterNamesAnimation;
